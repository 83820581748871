/**
 * Initial plan selection for new subscribers
 */
import PricingTable from './PricingTables/PricingTable';
import { COUNTRIES, FEATURE_SECTIONS, PRICING_TIERS } from './pricingV1Constants';

export default function ChooseYourPlan() {
  return (
    <PricingTable initialTiers={PRICING_TIERS} sections={FEATURE_SECTIONS} countries={COUNTRIES} />
  );
}
