import PropTypes from 'prop-types';

function PageLayoutInnerStrip({ children, hasTopBorder = false, className = '', hasFlex = true }) {
  const borderClasses = hasTopBorder
    ? 'border-y border-gray-900/10'
    : 'border-b border-gray-900/10';

  const flexClasses = hasFlex ? 'flex justify-between items-center' : '';

  return (
    <div className={`px-4 sm:px-6 lg:px-8 ${borderClasses} pt-5 py-4 ${flexClasses} ${className}`}>
      {children}
    </div>
  );
}

export default PageLayoutInnerStrip;

PageLayoutInnerStrip.propTypes = {
  children: PropTypes.node,
  hasTopBorder: PropTypes.bool,
  className: PropTypes.string,
  hasFlex: PropTypes.bool,
};
