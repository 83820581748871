// In some page component
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SourceGA4BigQueryFormStageEcommerce from 'components/_forms/source_ga4_bigquery_exports/Ecommerce/SourceGA4BigQueryFormStageEcommerce';
import { FormStateProviderEcommerce } from 'components/_forms/source_ga4_bigquery_exports/Ecommerce/SourceGA4BigQueryFormStateProvidersEcommerce';
import SourceGA4EventSample from 'components/_forms/source_ga4_bigquery_exports/Ecommerce/SourceGA4EventSampleEcommerce';
import SourceGA4BigQueryFormActionButtons from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryFormActionButtons';
import SourceGA4BigQueryFormNavigation from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryFormNavigation';
import PageAccordionStrip from 'components/PageAccordionStrip/PageAccordionStrip';
import { useGetPipelineByIdQuery, useGetSourceGA4BigQueryByIdQuery } from 'services/rtkApi';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const SourceGA4BigQueryFormPage = () => {
  const { id, pipelineId } = useParams(); // Get entityId from URL params
  const isUpdate = Boolean(id); // Determine if it's update mode
  const pageTitle = isUpdate ? 'Update GA4 Pipeline' : 'Create GA4 Pipeline'; // Set page title based on mode

  // We need to use RTK API to get our list of properties, bigquery projects, and bigquery datasets.
  // If we can't get that then the user is going to have a hard time creating a pipeline.

  const [propertyId, setPropertyId] = useState(null);
  const [propertyOptions, setPropertyOptions] = useState([]);

  const { data: sourceData } = useGetSourceGA4BigQueryByIdQuery(id, { skip: !isUpdate });
  const { data: pipelineData } = useGetPipelineByIdQuery(pipelineId, { skip: !pipelineId });

  useEffect(() => {
    if (sourceData) {
      const options = sourceData.property_ids.map((id) => ({ value: id, label: id }));
      setPropertyOptions(options);
      setPropertyId(sourceData.property_ids[0]);
    }
  }, [sourceData]);

  const handleSuccess = () => {
    // Handle what happens after successful form submission
    // Redirect or show a success message
  };

  return (
    <PageLayout isItGrey>
      <FormStateProviderEcommerce pipelineId={pipelineId}>
        <div>
          <PageLayoutInnerStrip>
            <h1 className="text-xl font-semibold leading-7 text-gray-900">{pageTitle}</h1>
            {isUpdate && (
              <SourceGA4BigQueryFormActionButtons
                pipelineId={pipelineId}
                sourceGA4BigQueryID={id}
              />
            )}
          </PageLayoutInnerStrip>
          <PageLayoutInner s>
            <div className="flex">
              <SourceGA4BigQueryFormNavigation isUpdate={isUpdate} activeTab="main" />
              <div className="flex-grow mt-5">
                <div className="text-gray-600 text-sm">
                  <p>
                    Are you an ecommerce site? If so you can use this section to extract ecommerce
                    data from your GA4 data.
                  </p>
                </div>
                <SourceGA4BigQueryFormStageEcommerce entityId={id} onSuccess={handleSuccess} />
                <PageAccordionStrip title="GA4 Item Data" className="mt-4">
                  <SourceGA4EventSample
                    property_id={propertyId}
                    project_id={pipelineData?.gcloud_project_id}
                    propertyOptions={propertyOptions}
                    onPropertyChange={setPropertyId}
                  />
                </PageAccordionStrip>
              </div>
            </div>
          </PageLayoutInner>
        </div>
      </FormStateProviderEcommerce>
    </PageLayout>
  );
};

export default SourceGA4BigQueryFormPage;
