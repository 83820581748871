import { zodResolver } from '@hookform/resolvers/zod';
import { createContext, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { sourceGA4BigQueryEcommerceSchemaForm } from 'schemas/sourceGA4DBT/sourceGA4DBTEcommerceSchema';

const FormContext = createContext(null);

export const FormStateProviderEcommerce = ({ children, pipelineId }) => {
  const methods = useForm({
    resolver: zodResolver(sourceGA4BigQueryEcommerceSchemaForm),
    mode: 'onBlur',
    defaultValues: {
      events_to_unnest: [],
      pipeline: pipelineId,
    },
  });

  return (
    <FormContext.Provider value={methods}>
      <FormProvider {...methods}>{children}</FormProvider>
    </FormContext.Provider>
  );
};

export const useFormStateEcommerce = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormStateEcommerce must be used within a FormStateProviderEcommerce');
  }
  return context;
};
