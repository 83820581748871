import { z } from 'zod';

/**
 * Schema for property builder.
 *
 */
const conditionSchema = z.object({
  col: z.string(),
  operator: z.enum(['equals', 'regex_contains', 'greater_than', 'less_than']),
  value: z.string(),
});

const formLogicBlockSchema = z.lazy(() =>
  z.object({
    andOr: z.enum(['and', 'or']),
    conditions: z.array(z.union([conditionSchema, formLogicBlockSchema])),
  })
);

const propertyConditionSchema = z.object({
  conditionBlock: formLogicBlockSchema,
  value: z.object({
    on_condition_true: z.number(),
    agg: z.enum(['count', 'sum', 'average']),
  }),
});

const propertyConditionBlockSchema = z.object({
  propertyConditions: z.array(propertyConditionSchema),
});

export const pipelineSchemaForm = z.object({
  id: z.number().nullable().optional(),
  test_boolean: z.boolean().default(false),
  name: z
    .string()
    .min(1, 'Name is required')
    .regex(
      /^[a-z0-9_]+[a-z]$/,
      "Name must start with a letter and contain only letters, numbers, and underscores. (And can't end with an underscore)"
    ),
  gcloud_project_id: z.string(),
  gcloud_region: z.string(),
  bq_output_dataset: z.string(),
  run_hour: z.number().min(0).max(23).optional(),
  timezone: z.string().default('+00:00'),
});

export const pipelineSchemaList = z.object({
  id: z.number().optional(),
  name: z.string(),
  gcloud_project_id: z.string(),
  gcloud_region: z.string(),
  bq_output_dataset: z.string(),
  run_hour: z.number().default(6),
  timezone: z.string(),
});

/**
 * Extra info
 *
 */
// Add a separate object for auto-set fields
export const autoSetFields = {
  gcloud_region: true,
};

export const fieldInfoPipeline = {
  id: { name: 'ID' },
  name: { name: 'Name', description: 'The name of the source' },
  gcloud_project_id: {
    name: 'Google Cloud Project ID',
    description: `Choose a Cloud Project. If you can see no options you'll need to create one/have it shared with you.`,
  },
  bq_output_dataset: {
    name: 'BigQuery Dataset',
    description: `Choose a dataset where we'll put all of your data. It needs to be in the same region as your GA4 export. {{button:Create new dataset:CREATE_DATASET_FORM}} You can see that/create one here.`,
  },
  gcloud_region: {
    name: 'Google Cloud Region',
    description: 'You want all your data to be in the same region.',
  },
  timezone: {
    name: 'Timezone',
    description:
      'Select the timezone. This is only used to choose the time of day the pipeline runs.',
  },
  run_hour: {
    name: 'Run Hour',
    description: 'Select the hour (0-23) when you want the pipeline to run daily',
  },
  currently_running: {
    name: 'Currently Running',
    description: 'Is the pipeline currently running?',
  },
  pipeline_status: {
    name: 'Pipeline Status',
    description: 'What is the status of the most recent pipeline run?',
  },
  gcloud_job_resource_name: {
    name: 'GCloud Job Name',
    description: 'The resource name of the Google Cloud job',
  },
  github_repo: {
    name: 'GitHub Repo',
    description: 'The GitHub repo with the DBT data.',
  },
  last_completed_run_status: {
    name: 'Last Completed Run Status',
    description: 'The status of the most recent pipeline run',
  },
};
