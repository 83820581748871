import PropTypes from 'prop-types';

import Button from 'components/Button/Button';
import {
  useGetCurrentUserQuery,
  useRunPipelineMutation,
  useUpdatePipelineDbtProjectMutation,
} from 'services/rtkApi';

/**
 * Renders a button strip for pipeline actions, including "Manually Run Pipeline" and "Update dbt Project".
 * @param {Object} props - Component props
 * @param {number} props.pipelineId - ID of the pipeline
 * @param {boolean} props.isDisabled - Whether the action should be disabled
 * @param {string} props.className - Additional CSS classes for the container
 */
const PipelineActionStrip = ({ pipelineId, isDisabled, className }) => {
  const [runPipeline, { isLoading }] = useRunPipelineMutation();
  const [updatePipelineDbtProject, { isLoading: isUpdatingDbtProject }] =
    useUpdatePipelineDbtProjectMutation();
  const { data: user } = useGetCurrentUserQuery(); // Use RTK Query directly

  const handleRunPipeline = async () => {
    try {
      await runPipeline({ id: pipelineId, run_type: 'ga4' });
      // Handle success (e.g., show a success message)
    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error('Failed to run pipeline:', error);
    }
  };

  const handleUpdateDbtProject = async () => {
    try {
      await updatePipelineDbtProject(pipelineId).unwrap();
      // Handle successful update (e.g., show a success message)
    } catch (error) {
      console.error('Failed to update dbt project:', error);
    }
  };

  return (
    <div className={`flex space-x-4 ${className}`}>
      <Button
        onClick={handleRunPipeline}
        loading={isLoading}
        disabled={isDisabled || isLoading}
        variant="primary"
        isOutline
      >
        Manually Run Pipeline
      </Button>
      {user?.is_superuser && (
        <Button
          onClick={handleUpdateDbtProject}
          loading={isUpdatingDbtProject}
          disabled={isUpdatingDbtProject}
          variant="primary"
        >
          Update dbt Project
        </Button>
      )}
    </div>
  );
};

PipelineActionStrip.propTypes = {
  pipelineId: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

PipelineActionStrip.defaultProps = {
  isDisabled: false,
  className: '',
};

export default PipelineActionStrip;
