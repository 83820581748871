import { useController } from 'react-hook-form';

const AndOrSelector = ({ control, name }) => {
  const { field } = useController({
    name,
    control,
    // Remove the defaultValue here, as it should come from the schema
  });

  const value = field.value ? field.value.toUpperCase() : 'AND';

  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        onClick={() => field.onChange('and')}
        className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ${
          value === 'AND'
            ? 'bg-blue-600 text-white'
            : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
        } focus:z-10`}
      >
        AND
      </button>
      <button
        type="button"
        onClick={() => field.onChange('or')}
        className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ${
          value === 'OR'
            ? 'bg-blue-600 text-white'
            : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
        } focus:z-10`}
      >
        OR
      </button>
    </span>
  );
};

export default AndOrSelector;
