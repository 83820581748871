/**
 * A progress indicator component showing current step and navigation dots
 * @param {Object} props
 * @param {Array<{name: string, href: string}>} props.steps - Array of step objects
 * @param {string} props.currentStep - Name of the current step
 */
const ProgressBar = ({ steps, currentStep }) => {
  const currentStepIndex = steps.findIndex((step) => step.name === currentStep);

  const getStatus = (index) => {
    if (index === currentStepIndex) return 'current';
    if (index < currentStepIndex) return 'complete';
    return 'upcoming';
  };

  return (
    <nav
      aria-label="Progress"
      className="flex items-center justify-center border border-gray-200 rounded-lg p-4"
    >
      <p className="text-sm font-medium">
        {currentStepIndex + 1}. {currentStep}
      </p>
      <ol role="list" className="ml-8 flex items-center space-x-5">
        {steps.map((step, index) => (
          <li key={step.name}>
            {getStatus(index) === 'complete' ? (
              <a
                href={step.href}
                className="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900"
              >
                <span className="sr-only">{step.name}</span>
              </a>
            ) : getStatus(index) === 'current' ? (
              <a
                href={step.href}
                aria-current="step"
                className="relative flex items-center justify-center"
              >
                <span aria-hidden="true" className="absolute flex h-5 w-5 p-px">
                  <span className="h-full w-full rounded-full bg-indigo-200" />
                </span>
                <span
                  aria-hidden="true"
                  className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600"
                />
                <span className="sr-only">{step.name}</span>
              </a>
            ) : (
              <a
                href={step.href}
                className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
              >
                <span className="sr-only">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default ProgressBar;

// Usage example:
// const steps = [
//   { name: 'Details', href: '#' },
//   { name: 'Address', href: '#' },
//   { name: 'Payment', href: '#' },
// ]
// <ProgressBar steps={steps} currentStep="Address" /> // Shows "2. Address"
