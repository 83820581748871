import { z } from 'zod';

/**
 * DEFINTIION: Advanced Configuration
 */
export const sourceGA4BigQueryAdvancedSchemaForm = z.object({
  // How many days lookback is our attribution window?
  lndc_attribution_window_days: z.number().optional().default(90),

  // Do we want to use user ID?
  user_id_enabled: z.boolean().optional(),
});

export const fieldInfoSourceGA4DBTAdvancedSchema = {
  lndc_attribution_window_days: {
    name: 'Attribution Window',
    description:
      'How many days lookback do you want to use for the default GA4 attribution window? (GA4 uses 90 by default).',
  },
  user_id_enabled: { name: 'User ID Enabled', description: 'Are you using user ID?' },
};
