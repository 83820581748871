import {
  BookOpenIcon, // New import
  ClipboardDocumentListIcon,
  PlusIcon, // New import
} from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CommonActions from 'components/CommonActions/CommonActions';
import GoogleProjectSwitcher from 'components/GoogleProjectSwitcher/GoogleProjectSwitcher';
import { PIPELINE_CREATE_FORM, PIPELINE_LIST } from 'routes/constants';
import PageLayout from 'templates/PageLayout';

const actions = [
  {
    title: 'Add a new pipeline',
    href: PIPELINE_CREATE_FORM,
    icon: PlusIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
    description: "You'll want one pipeline for each set of GA4 properties you want to record",
  },
  {
    title: 'Check your existing pipelines',
    href: PIPELINE_LIST,
    icon: ClipboardDocumentListIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    description: 'Look at the status of your existing pipelines and GA4 loads',
  },
  {
    title: 'Documentation',
    href: '#',
    icon: BookOpenIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-teal-50',
    description: "We've got a lot of documentation to help you get started with your GA4 data.",
  },
];

const Home = () => {
  const dispatch = useDispatch();
  const restCheck = useSelector((state) => state.restCheck);
  useEffect(() => {
    // const action = fetchRestCheck();
    // dispatch(action);
  }, [dispatch]);

  // const [showBugComponent, setShowBugComponent] = useState(false);

  return (
    <PageLayout>
      <div className="px-4 sm:px-6 lg:px-8 py-10  mx-20">
        <GoogleProjectSwitcher />
        <CommonActions actions={actions} />
      </div>
    </PageLayout>
  );
};

export default Home;
