import { z } from 'zod';

import {
  propertyConditionBoolsSchema,
  propertyConditionStringNumberSchema,
} from './sourceGA4PropertiesFormBase';

const propertyConditionBlockStringNumberSchema = z.object({
  name: z.string(),
  propertyConditionBlock: z.object({
    propertyConditions: propertyConditionStringNumberSchema,
  }),
});

const propertyConditionBlockBoolsSchema = z.object({
  name: z.string(),
  propertyConditionBlock: z.object({
    andOr: z.enum(['and', 'or']).optional(),
    propertyConditions: propertyConditionBoolsSchema,
  }),
});

/**
 * DEFINTIION: Session Properties that are numbers/strings
 */
export const sourceGA4BigQuerySessionPropertiesNumberSchemaForm =
  propertyConditionBlockStringNumberSchema;

/**
 * DEFINTIION: Session Properties that are booleans
 */
export const sourceGA4BigQuerySessionPropertiesBoolsSchemaForm = propertyConditionBlockBoolsSchema;

/**
 * Field info for the session properties form
 */
export const fieldInfoSourceGA4DBTSessionProperties = {
  // Session Properties
  propertyConditionBlock: {
    name: 'Time to set-up your session property',
    showLabel: true,
    showDescription: true,
    showMasterLabel: false,
    showMasterDescription: false,
    description:
      'You can have one property block, or mutliple ones which will all be evaluated to set true.',
    fields: {
      propertyConditions: {
        name: '1. Setup conditions for your session property.',
        showLabel: true,
        showArrayLabel: false,
        fields: {
          conditionBlock: {
            name: 'When these conditions are true.',
            showLabel: false,
            fields: {
              andOr: {
                name: 'And/Or',
                description:
                  'Choose whether all conditions should match (AND) or any condition should match (OR)',
              },
              conditions: {
                name: 'Conditions',
                showLabel: false,
                fields: {
                  col: {
                    name: 'Column',
                  },
                  operator: {
                    name: 'Operator',
                  },
                  value: {
                    name: 'Value',
                  },
                },
              },
            },
          },
          value: {
            name: '2. When the conditions are true, this will be the value of your session property.',
            description: 'The value to set when the condition is true',
            fields: {
              col: {
                name: 'Pick a column',
              },
              on_condition_true: {
                name: 'Static Value',
                // description: 'The value to set when the condition is true',
              },
              agg: {
                name: 'Aggregation',
                // description: 'The aggregation method to use',
              },
            },
          },
          compare: {
            name: '3. (Optional) Compare to another value to get true/false.',
            description: 'Compare the value to the value below',
            fields: {
              operator: {
                name: 'Operator',
              },
            },
          },
        },
      },
    },
  },
};
