export const conditionColBasePath = [
  'propertyConditionBlock',
  'propertyConditions',
  '*',
  'conditionBlock',
  'conditions',
  '*',
];
export const propertyConditionsValueBasePath = [
  'propertyConditionBlock',
  'propertyConditions',
  '*',
  'value',
];
export const conditionColPath = [...conditionColBasePath, 'col'];
export const conditionColTypePath = [...conditionColBasePath, 'col_type'];
export const conditionOperatorBasePath = [...conditionColBasePath, 'operator'];
export const propertyConditionsColTypePath = [...propertyConditionsValueBasePath, 'col_type'];
export const propertyConditionsValuePath = [...propertyConditionsValueBasePath, 'value'];
export const propertyConditionsValueColPath = [...propertyConditionsValueBasePath, 'col'];
