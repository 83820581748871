import SourceGA4BigQueryTable from 'components/_tables/source_ga4_bigquery/SourceGA4BigQueryTable';
import Button from 'components/Button/Button';
import { PIPELINE_CREATE_FORM } from 'routes/constants';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const SourceGA4BigQueryListPage = () => {
  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">List GA4 Pipelines</h1>
          <Button href={PIPELINE_CREATE_FORM}>Create GA4 Pipeline</Button>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <SourceGA4BigQueryTable />
        </PageLayoutInner>
        {/* For update, pass entityId prop */}
      </div>
    </PageLayout>
  );
};

export default SourceGA4BigQueryListPage;
