import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'; // Adjust the import path as needed
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import { googleAPIAuthRegisterRequest } from 'services/auth/authSlice';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const GoogleApiAuthCallback = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const state = params.get('state');
    const provider = 'google-oauth2';

    if (code) {
      dispatch(googleAPIAuthRegisterRequest({ provider, code, state }));
      console.log('redirecting to home');
      // console.log(code);
      // navigate('/'); // Redirect to the homepage or dashboard
    } else {
      // navigate('/login'); // Redirect to login page if no code is present
    }
  }, [dispatch, location, navigate]);

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Settings</h1>
          <div>Loading...</div>
          <div>
            <LoadingSpinner />
          </div>
        </PageLayoutInnerStrip>
        <PageLayoutInner />
      </div>
    </PageLayout>
  );
};

export default GoogleApiAuthCallback;
