import { autocompletion } from '@codemirror/autocomplete';
import { sql } from '@codemirror/lang-sql';
import { materialDark } from '@uiw/codemirror-theme-material';
import CodeMirror from '@uiw/react-codemirror';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import useHighlightedWordsInCodeMirror from 'hooks/useHighlightedWordsInCodeMirror';

import styles from './SQLEditor.module.scss';

/**
 * SQLEditor component for displaying and editing SQL with highlighted words
 * @param {Object} props - Component props
 * @param {string} props.value - SQL content
 * @param {string[]} props.highlightWords - List of words to highlight
 * @param {Function} props.onChange - Callback function for changes
 * @param {Function} props.onMount - Callback function when editor is mounted
 * @param {number} props.errorLine - Error line number
 * @param {number} props.errorCharacter - Error character position
 */
function SQLEditor({ value, highlightWords = [], onChange, onMount, errorLine, errorCharacter }) {
  const highlightPlugin = useHighlightedWordsInCodeMirror(
    highlightWords,
    styles,
    errorLine,
    errorCharacter
  );

  const handleChange = useCallback(
    (value, viewUpdate) => {
      onChange(value);
    },
    [onChange]
  );

  const myCompletions = useCallback((context) => {
    const word = context.matchBefore(/\w*/);
    if (word.from === word.to && !context.explicit) return null;
    return {
      from: word.from,
      options: [
        { label: 'SELECT', type: 'keyword' },
        { label: 'FROM', type: 'keyword' },
        { label: 'WHERE', type: 'keyword' },
        { label: 'table_name', type: 'variable', info: 'Example table' },
        { label: 'column_name', type: 'variable', info: 'Example column' },
      ],
    };
  }, []);

  const autocompleteExtension = useMemo(() => {
    return autocompletion({ override: [myCompletions] });
  }, [myCompletions]);

  return (
    <CodeMirror
      value={value}
      height="500px"
      extensions={[sql(), highlightPlugin, autocompleteExtension]}
      onChange={handleChange}
      onCreateEditor={onMount}
      theme={materialDark}
    />
  );
}

SQLEditor.propTypes = {
  value: PropTypes.string.isRequired,
  highlightWords: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  errorLine: PropTypes.number,
  errorCharacter: PropTypes.number,
};

export default SQLEditor;
