import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useFilters, useSortBy, useTable } from 'react-table';

import Button from 'components/Button/Button';
import FormInputNonHookForm from 'components/FormNonReactHook/FormInputNonHookForm';
import FormSelectNonHookForm from 'components/FormNonReactHook/FormSelectNonHookForm';

function FlexListTable({
  columns,
  data,
  initialSort,
  linkButtons,
  hiddenColumnIds = ['id'],
  linkIdKey = 'id',
  linkIdString = ':id',
}) {
  const initialState = {
    hiddenColumns: hiddenColumnIds,
  };

  if (initialSort && initialSort.length) {
    initialState.sortBy = initialSort;
  }

  /* eslint-disable react/prop-types */
  /**
   * This defines the default filter behaviours for the table.
   */
  const defaultColumn = useMemo(
    () => ({
      Filter: ({ column }) => {
        if (!column.filter || !column.filter.enabled) return null;

        const [filterValue, setFilterValue] = useState(column.filterValue || '');

        if (column.filter.enum) {
          const options = [...new Set(data.map((item) => item[column.id]))].map((value) => ({
            value,
            label: value,
          }));
          options.unshift({ value: '', label: 'All' });

          return (
            <FormSelectNonHookForm
              fieldKey={column.id}
              label={column.Header}
              value={filterValue}
              onChange={(value) => {
                setFilterValue(value);
                column.setFilter(value || undefined);
              }}
              options={options}
            />
          );
        }

        return (
          <FormInputNonHookForm
            fieldKey={column.id}
            label={column.Header}
            value={filterValue}
            onChange={(value) => {
              setFilterValue(value);
              column.setFilter(value || undefined);
            }}
            placeholder={`Filter ${column.Header}`}
          />
        );
      },
    }),
    [data]
  );
  /* eslint-enable react/prop-types */

  /**
   * LINK BUTTONS
   * This adds in a row of buttons onto the table if required.
   */
  const modifiedColumns = useMemo(() => {
    const cols = columns.map((column) => ({
      ...column,
      disableFilters: !(column.filter && column.filter.enabled),
    }));

    if (linkButtons && linkButtons.length > 0) {
      cols.push({
        Header: 'Actions',
        id: 'actions',
        disableSortBy: true,
        disableFilters: true,
        assignedWidth: 16,
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            {linkButtons
              .filter((button) => button.show !== false)
              .map((button, buttonIndex) => (
                <Button
                  key={buttonIndex}
                  href={
                    button.link ? button.link.replace(':id', row.original[linkIdKey]) : undefined
                  }
                  onClick={button.onClick ? () => button.onClick(row.original) : undefined}
                >
                  {button.label}
                </Button>
              ))}
          </div>
        ),
      });
    }
    return cols;
  }, [columns, linkButtons, linkIdKey]);

  /**
   * GENERIC TABLE SETUP
   * The standard react table setup.
   */
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
    {
      columns: modifiedColumns,
      data,
      defaultColumn,
      initialState,
    },
    useFilters,
    useSortBy
  );

  function generateMinWidthStyle(column) {
    if (column.assignedWidth) {
      return {
        className: 'fixed-width-column',
        style: { minWidth: `${column.assignedWidth}rem`, maxWidth: `${column.assignedWidth}rem` },
      };
    }
    // For dynamic columns, you might only need the class name for equal distribution
    return { className: 'flex-column', style: {} };
  }

  return (
    <div className="overflow-x-auto shadow-md sm:rounded-lg">
      <div className="mb-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 mb-4">
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.canFilter ? (
              <div key={column.id} className="col-span-1">
                {column.render('Filter')}
              </div>
            ) : null
          )
        )}
      </div>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 md:table-header-group hidden">
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
              {headerGroup.headers.map((column, columnIndex) => {
                const { className, style } = generateMinWidthStyle(column);
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={columnIndex}
                    className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${className} ${
                      column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''
                    }`}
                    style={style}
                  >
                    <div className="flex items-center">
                      {column.render('Header')}
                      {column.isSorted && (
                        <span className="ml-1">
                          {column.isSortedDesc ? (
                            <ChevronDownIcon className="w-4 h-4" />
                          ) : (
                            <ChevronUpIcon className="w-4 h-4" />
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={rowIndex}
                className="hover:bg-gray-100 md:table-row flex flex-col"
              >
                {row.cells.map((cell, cellIndex) => {
                  const { className, style } = generateMinWidthStyle(cell.column);
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={cellIndex}
                      className={`px-6 py-4 text-sm text-gray-500 ${className} md:table-cell block`}
                      style={style}
                    >
                      <span className="md:hidden font-medium capitalize mr-2">
                        {cell.column.Header}:
                      </span>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

FlexListTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  initialSort: PropTypes.array,
  linkButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      show: PropTypes.bool,
    })
  ),
  hiddenColumnIds: PropTypes.array,
  linkIdKey: PropTypes.string,
  linkIdString: PropTypes.string,
};

export default FlexListTable;
