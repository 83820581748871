import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Sidebar from 'components/Sidebar/Sidebar'; // Adjust with your Sidebar import
import TopBar from 'components/TopBar/TopBar'; // Adjust with your TopBar import

function LoggedInLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Set isMinimized to false if the route contains 'ga4-bigquery/edit', true otherwise
    setIsMinimized(location.pathname.includes('ga4-bigquery/edit'));
  }, [location]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        isMinimized={isMinimized}
        setIsMinimized={setIsMinimized}
      />

      {/* Content area */}
      <div
        className={`flex flex-col flex-1 overflow-hidden transition-all duration-300 ${
          isMinimized ? 'lg:ml-16' : 'lg:ml-72'
        }`}
      >
        {/* Top Bar */}
        <TopBar setSidebarOpen={setSidebarOpen} />

        {/* Main content */}
        <main className="flex-1 overflow-y-auto">
          <div className="">{children}</div>
        </main>
      </div>
    </div>
  );
}

LoggedInLayout.propTypes = {
  children: PropTypes.node,
};

export default LoggedInLayout;
