import { z } from 'zod';

export const sourceGA4BigQueryChannelGroupingSchemaForm = z.object({
  extra_channel_groupings: z.enum(['custom_channel_grouping_1', 'null']).nullable(),
});

export const fieldInfoSourceGA4DBTChannelGrouping = {
  id: { name: 'ID' },
  extra_channel_groupings: {
    name: 'Extra Channel Groupings',
    description: 'Select an additional channel grouping',
    type: 'select',
    options: [
      { value: null, label: 'None' },
      { value: 'custom_channel_grouping_1', label: 'Custom Channel Grouping 1' },
    ],
  },
};
