import { useEffect, useMemo, useState } from 'react';

import {
  useGetBigQueryDatasetsQuery,
  useGetPipelineByIdQuery,
  useLazyGetGA4PropertyByIdQuery,
} from 'services/rtkApi';

export const useGA4BigQueryFormData = (pipelineId) => {
  const [formData, setFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    data: pipelineData,
    isLoading: isPipelineLoading,
    error: pipelineError,
  } = useGetPipelineByIdQuery(pipelineId);

  // Go and get all the datasets.
  const {
    data: datasetsData,
    isLoading: isDatasetsLoading,
    error: datasetsError,
  } = useGetBigQueryDatasetsQuery(
    { project_id: pipelineData?.gcloud_project_id },
    { skip: !pipelineData }
  );

  // Get data for a specific GA4 property.
  const [getGA4Property, { isLoading: isPropertyLoading }] = useLazyGetGA4PropertyByIdQuery();

  // For each dataset, go and fetch the name.
  const propertyIds = useMemo(() => {
    if (!datasetsData) return [];
    return datasetsData
      .filter((dataset) => /analytics_\d{7,12}$/.test(dataset.name))
      .map((dataset) => dataset.name.match(/\d{7,12}/)[0]);
  }, [datasetsData]);

  const getGA4PropertyMemo = useMemo(
    () => (id) =>
      getGA4Property({
        propertyId: id,
        projectId: pipelineData?.gcloud_project_id,
      }),
    [getGA4Property, pipelineData?.gcloud_project_id]
  );

  useEffect(() => {
    const fetchProperties = async () => {
      if (pipelineData && datasetsData && propertyIds.length > 0) {
        setIsLoading(true);
        setError(null);
        try {
          const propertyPromises = propertyIds.map(async (id) => {
            try {
              const result = await getGA4PropertyMemo(id).unwrap();
              return {
                value: result.property_id,
                label: result.display_name,
              };
            } catch (err) {
              // If the request fails, return the property ID as both value and label
              return {
                value: id,
                label: `Unknown - ${id}`,
              };
            }
          });
          const propertyResults = await Promise.all(propertyPromises);
          setFormData(propertyResults);
        } catch (err) {
          setError(err);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchProperties();
  }, [pipelineData, datasetsData, propertyIds, getGA4PropertyMemo]);

  useEffect(() => {
    setIsLoading(
      isPipelineLoading ||
        isDatasetsLoading ||
        isPropertyLoading ||
        (pipelineData && datasetsData && propertyIds.length > 0 && formData.length === 0)
    );
  }, [
    isPipelineLoading,
    isDatasetsLoading,
    isPropertyLoading,
    pipelineData,
    datasetsData,
    propertyIds,
    formData,
  ]);

  useEffect(() => {
    setError(pipelineError || datasetsError);
  }, [pipelineError, datasetsError]);

  return { formData, isLoading, error };
};
