import { json } from '@codemirror/lang-json';
import { linter, lintGutter } from '@codemirror/lint';
import { materialDark } from '@uiw/codemirror-theme-material';
import CodeMirror from '@uiw/react-codemirror';
import { jsonrepair } from 'jsonrepair';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import Button from 'components/Button/Button';
import useHighlightedWordsInCodeMirror from 'hooks/useHighlightedWordsInCodeMirror';

import styles from './JsonEditor.module.scss';

/**
 * JsonEditor component for displaying and editing raw JSON data
 * @param {Object} props - Component props
 * @param {Object|string} props.data - Initial JSON data or string
 * @param {Function} props.onDataChange - Callback function for data changes
 * @param {Function} props.onValidationChange - Callback function for validation changes
 * @param {Object} [props.zodSchema] - Optional Zod schema for validation
 * @param {string[]} [props.highlightWords=[]] - List of words to highlight
 */
function JsonEditor({ data, onDataChange, onValidationChange, zodSchema, highlightWords = [] }) {
  const [jsonValue, setJsonValue] = useState(data);
  const [error, setError] = useState(null);
  const [zodErrors, setZodErrors] = useState([]);
  const [isValidateLoading, setIsValidateLoading] = useState(false);
  const [isFixLoading, setIsFixLoading] = useState(false);

  // Add this useEffect to update jsonValue when data prop changes
  useEffect(() => {
    setJsonValue(data);
  }, [data]);

  const handleChange = useCallback(
    (value) => {
      setJsonValue(value);
      setError(null);
      setZodErrors([]);
      onDataChange(value);
      // validateJson(value);
    },
    [onDataChange]
  );

  console.log('highlightWords', highlightWords);

  const validateJson = useCallback(
    (value) => {
      let isJsonValid = true;
      let isSchemaValid = true;

      try {
        JSON.parse(value);
      } catch (e) {
        isJsonValid = false;
        isSchemaValid = false;
        setError(`Invalid JSON: ${e.message}`);
      }

      if (isJsonValid && zodSchema) {
        try {
          const parsedJson = JSON.parse(value);
          const result = zodSchema.safeParse(parsedJson);
          if (!result.success) {
            isSchemaValid = false;
            setZodErrors(result.error.issues);
          } else {
            setZodErrors([]);
          }
        } catch (e) {
          isSchemaValid = false;
          console.error('Error validating schema:', e);
        }
      }

      onValidationChange(isJsonValid, isSchemaValid);
    },
    [zodSchema, onValidationChange]
  );

  const handleValidateClick = useCallback(() => {
    setIsValidateLoading(true);
    validateJson(jsonValue);
    setTimeout(() => setIsValidateLoading(false), 500);
  }, [jsonValue, validateJson]);

  const handleFixJson = useCallback(async () => {
    setIsFixLoading(true);
    try {
      const fixedJson = jsonrepair(jsonValue);
      setJsonValue(fixedJson);
      onDataChange(fixedJson);
      validateJson(fixedJson);
    } catch (e) {
      setError(`Unable to fix JSON: ${e.message}`);
      onValidationChange(false, false);
    } finally {
      setTimeout(() => setIsFixLoading(false), 500);
    }
  }, [jsonValue, validateJson, onValidationChange, onDataChange]);

  const zodLinter = linter((view) => {
    if (zodErrors.length === 0) return [];

    const { doc } = view.state;
    let parsedJson;
    try {
      parsedJson = JSON.parse(doc.toString());
    } catch (e) {
      return [];
    }

    const lintErrors = zodErrors.map((error) => {
      const { path } = error;
      const value = get(parsedJson, path);
      const parentPath = path.slice(0, -1);
      const parentValue = get(parsedJson, parentPath);
      const lastKey = path[path.length - 1];

      let from = 0;
      let to = doc.length;

      // Function to find the position of a value or key in the document
      const findPosition = (searchValue, isKey = false) => {
        for (let i = 0; i < doc.lines; i++) {
          const line = doc.line(i + 1);
          const lineText = line.text;
          let searchIndex;
          if (isKey) {
            searchIndex = lineText.indexOf(`"${searchValue}"`);
          } else {
            searchIndex = lineText.indexOf(JSON.stringify(searchValue));
          }
          if (searchIndex !== -1) {
            return {
              from: line.from + searchIndex,
              to:
                line.from +
                searchIndex +
                (isKey ? searchValue.length + 2 : JSON.stringify(searchValue).length),
            };
          }
        }
        return null;
      };

      let position;
      console.log('value', value);
      console.log('parentValue', parentValue);
      if (value !== undefined) {
        // If the value exists, find its position
        position = findPosition(value);
      } else if (parentValue !== undefined) {
        // If the value is missing, find the position of the parent object's opening brace
        position = findPosition(parentValue);
        console.log('position', position);
        if (position) {
          // Adjust to point to the end of the opening brace
          position.from = position.to - 1;
          position.to = position.from + 1;
        }
      }

      if (position) {
        from = position.from;
        to = position.to;
      }

      return {
        from,
        to,
        message: `${error.message} (at ${path.join('.')})`,
        severity: 'error',
      };
    });

    console.log('lintErrors', lintErrors);

    return lintErrors;
  });

  console.log('highlightWords', highlightWords);

  const highlightPlugin = useHighlightedWordsInCodeMirror(highlightWords, styles);

  return (
    <div>
      <CodeMirror
        value={jsonValue}
        height="400px"
        extensions={[json(), lintGutter(), zodLinter, highlightPlugin]}
        onChange={handleChange}
        theme={materialDark}
      />
      <div className="mt-4">
        <Button
          onClick={handleValidateClick}
          variant="primary"
          isOutline
          className="mr-2"
          loading={isValidateLoading}
        >
          Validate
        </Button>
        <Button onClick={handleFixJson} variant="primary" isOutline loading={isFixLoading}>
          Fix
        </Button>
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {zodErrors.length > 0 && (
        <div style={{ color: 'orange' }}>
          Schema validation errors:
          <ul>
            {zodErrors.map((error, index) => (
              <li key={index}>
                {error.message} (at {error.path.join('.')})
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default JsonEditor;
