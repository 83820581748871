// import { z } from 'zod';

// export const conditionSchema = z.object({
//   col: z.string(),
//   col_type: z.string().describe({ hidden: true }),
//   operator: z.enum(['=', '!=', '>', '<', '>=', '<=', 'regex_contains']),
//   value: z.string(),
// });

// export const formLogicBlockSchema = z.object({
//   andOr: z.enum(['and', 'or']).default('and'),
//   conditions: z.array(conditionSchema).optional(),
// });

// export const valueSchema = z
//   .object({
//     col: z.string().nullable().optional(),
//     col_type: z.string().optional().nullable().describe({ hidden: true }),
//     agg: z.enum(['sum', 'count', 'distinct_count', 'max', 'last_value', 'first_value']),
//     on_condition_true: z.string().optional(),
//   })
//   .refine(
//     (data) => {
//       console.log('Refining valueSchema:', data);
//       return (data.col && !data.on_condition_true) || (!data.col && data.on_condition_true);
//     },
//     {
//       message: 'You need to either pick a column or a static value.',
//       path: ['on_condition_true'],
//     }
//   );

// export const propertyConditionSchema = z.object({
//   conditionBlock: formLogicBlockSchema.optional(),
//   value: valueSchema,
//   compare: z
//     .object({
//       operator: z.string(),
//       value: z.string(),
//     })
//     .optional(),
// });

import { z } from 'zod';

export const conditionSchema = z.object({
  col: z.string(),
  operator: z.enum(['=', '>', '<', '>=', '<=', 'regex_contains']).describe({
    '=': 'Equals',
    '>': 'Greater than',
    '<': 'Less than',
    '>=': 'Greater than or equal to',
    '<=': 'Less than or equal to',
    regex_contains: 'Regex contains',
  }),
  value: z.string(),
});

export const formLogicBlockSchema = z.object({
  andOr: z.enum(['and', 'or']).default('and'),
  conditions: z.array(conditionSchema).nullable().optional(),
});

export const valueSchema = z
  .object({
    col: z.string().nullable().optional(),
    col_type: z.string().optional().nullable().describe({ hidden: true }),
    agg: z.enum(['sum', 'count', 'distinct_count', 'max', 'last_value', 'first_value']),
    on_condition_true: z.string().optional(),
  })
  .refine(
    (data) => {
      console.log('Refining valueSchema:', data);
      return (data.col && !data.on_condition_true) || (!data.col && data.on_condition_true);
    },
    {
      message: 'You need to either pick a column or a static value.',
      path: ['on_condition_true'],
    }
  );

export const propertyConditionBoolsSchema = z.array(
  z.object({
    conditionBlock: formLogicBlockSchema.optional(),
    value: valueSchema,
    compare: z
      .object({
        operator: z.enum(['=', '!=', '>', '<', '>=', '<=']).describe({
          '=': 'Equals',
          '!=': 'Not equals',
          '>': 'Greater than',
          '<': 'Less than',
          '>=': 'Greater than or equal to',
          '<=': 'Less than or equal to',
        }),
        value: z.string(),
      })
      .optional(),
  })
);

export const propertyConditionStringNumberSchema = z
  .array(
    z.object({
      conditionBlock: formLogicBlockSchema.optional(),
      value: valueSchema,
    })
  )
  .min(1)
  .max(1);
