import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import { SOURCE_GA4_BIGQUERY_UPDATE_FORM } from 'routes/constants';
import {
  fieldInfoSourceGA4DBTAdvancedSchema,
  sourceGA4BigQueryAdvancedSchemaForm,
} from 'schemas/sourceGA4DBT/sourceGA4DBTAdvancedSchema';
import {
  useAddSourceGA4BigQueryMutation,
  useGetSourceGA4BigQueryByIdQuery,
  useUpdateSourceGA4BigQueryMutation,
} from 'services/rtkApi';

const SourceGA4BigQueryFormAdvanced = ({ onSuccess }) => {
  const { id, pipelineId } = useParams();
  const navigate = useNavigate();
  const is_update = Boolean(id);
  const form_type = is_update ? 'update' : 'create';

  const mutation_hook = is_update
    ? useUpdateSourceGA4BigQueryMutation
    : useAddSourceGA4BigQueryMutation;
  const query_hook = is_update ? useGetSourceGA4BigQueryByIdQuery : undefined;

  const handle_success = React.useCallback(
    (result_id) => {
      const update_url = SOURCE_GA4_BIGQUERY_UPDATE_FORM.replace(':pipelineId', pipelineId).replace(
        ':id',
        result_id
      );
      navigate(update_url);
    },
    [pipelineId, navigate]
  );

  const pre_submit = React.useCallback(
    (form_data) => {
      return {
        ...form_data,
      };
    },
    [pipelineId]
  );

  return (
    <CrudForm
      entityId={id}
      key={`pipeline_${form_type}`}
      formType={form_type}
      mutationHook={mutation_hook}
      queryHook={query_hook}
      schema={sourceGA4BigQueryAdvancedSchemaForm}
      fieldInfo={fieldInfoSourceGA4DBTAdvancedSchema}
      onSuccess={handle_success}
      warnOnUnsavedChanges
      preSubmit={pre_submit}
    />
  );
};

export default SourceGA4BigQueryFormAdvanced;
