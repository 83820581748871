import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import {
  fieldInfoSourceGA4DBTChannelGrouping,
  sourceGA4BigQueryChannelGroupingSchemaForm,
} from 'schemas/sourceGA4DBT/sourceGA4DBTChannelGrouping';
import { useGetDbtProjectFileQuery, usePartialUpdateDbtProjectFileMutation } from 'services/rtkApi';

/**
 * SourceGA4BigQueryFormChannelGroupings component for editing GA4 BigQuery channel groupings
 * @param {Object} props - Component props
 * @param {Function} props.onSuccess - Callback function to be called on successful form submission
 */
function SourceGA4BigQueryFormChannelGroupingToggle() {
  const { id, pipelineId } = useParams();
  const [hiddenFields, setHiddenFields] = useState([]);

  const methods = useForm({
    resolver: zodResolver(sourceGA4BigQueryChannelGroupingSchemaForm),
    mode: 'onBlur',
    defaultValues: {
      extra_channel_groupings: 'custom_channel_grouping_1',
    },
  });

  const mutationHook = usePartialUpdateDbtProjectFileMutation;
  const queryHook = useGetDbtProjectFileQuery;

  const onFieldChange = useCallback((formValues, changedFields) => {
    console.log('formValues', formValues);
    console.log('changedFields', changedFields);
  }, []);

  const preSubmit = useCallback(
    (formData) => {
      return {
        ...formData,
        pipeline: pipelineId,
      };
    },
    [pipelineId]
  );

  const foreignKeyOptions = useMemo(
    () => [
      {
        key: ['extra_channel_groupings'],
        options: [{ value: 'custom_channel_grouping_1', label: 'Custom Channel Grouping 1' }],
      },
    ],
    []
  );

  return (
    <div className="mb-8">
      <CrudForm
        entityId={id}
        formType="update"
        mutationHook={mutationHook}
        queryHook={queryHook}
        schema={sourceGA4BigQueryChannelGroupingSchemaForm}
        foreignKeyOptions={foreignKeyOptions}
        fieldInfo={fieldInfoSourceGA4DBTChannelGrouping}
        onFieldChange={onFieldChange}
        preSubmit={preSubmit}
        hiddenFields={hiddenFields}
        methods={methods}
        formTitle="GA4 BigQuery Channel Groupings"
      />
    </div>
  );
}

export default SourceGA4BigQueryFormChannelGroupingToggle;
