import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import FormSelect from '../FormSelect/FormSelect';

const hourOptions = Array.from({ length: 24 }, (_, i) => ({
  value: i,
  label: `${i.toString().padStart(2, '0')}:00`,
}));

const FormHourSelect = (
  { fieldKey, label, control, errors, description, disabled = false, className },
  ref
) => {
  const errorMessage = errors?.message || (typeof errors === 'string' ? errors : null);

  return (
    <>
      <FormSelect
        ref={ref}
        fieldKey={fieldKey}
        label={label}
        control={control}
        errors={errors}
        options={hourOptions}
        description={description}
        disabled={disabled}
        className={className}
      />
      {errorMessage && (
        <p className="mt-2 pb-0 text-sm text-red-500" id={`${fieldKey}-description`}>
          {errorMessage}
        </p>
      )}
    </>
  );
};

export default forwardRef(FormHourSelect);

FormHourSelect.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
