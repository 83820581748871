import { z } from 'zod';

/**
 * DEFINTIION: Advanced Configuration
 */
export const sourceGA4BigQueryAdvancedBQSchemaForm = z.object({
  // Do we coerce session ID to a string?
  ga_session_id_str: z.boolean().optional(),

  // How many extra days rolling do we want to recalculate?
  static_incremental_days: z.number().optional().default(3),

  // Do we have app first open events without session?
  first_open_without_session: z.boolean().optional(),
});

export const fieldInfoSourceGA4DBTAdvancedBQSchema = {
  ga_session_id_str: {
    name: 'Is GA Session ID a string?',
    description:
      'GA session ID is a number by default, but some parties send it as a string. Do you want to coerce it to a string?',
  },
  static_incremental_days: {
    name: 'Rolling recalculation window',
    description:
      'GA4 data is only finalised after 72 hours, so we recommend a rolling recalculation window of 3 days. If you have a very large site, lowering this will reduce your data costs.',
  },
  first_open_without_session: {
    name: 'Add first open events to a session',
    description:
      'First open events can sometimes be sent without a session. We can attempt to stitch them back onto one when possible.',
  },
};
