/**
 * SectionHeading component for displaying consistent section headers
 * @param {Object} props - Component props
 * @param {string} props.text - The text to display in the heading
 */
function SubTitle({ text }) {
  return <h2 className="text-base font-semibold leading-7 text-gray-900">{text}</h2>;
}

export default SubTitle;
