import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import FormPropertyList from 'components/Forms/FormPropertyList/FormPropertyList';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useGA4TableSchema from 'hooks/ga4Form/useGA4TableSchema';
import { useGetDbtProjectFileQuery } from 'services/rtkApi';

import SourceGA4BigQueryFormSingleConversionsNew from './SourceGA4BigQueryFormSingleConversionsNew';
import useGA4ConversionTemplates from './useGA4ConversionTemplates';

const emptyConversion = {
  name: 'New conversion',
  propertyConditionBlock: {
    conditionBlock: {
      andOr: 'and',
      conditions: [],
    },
    value: {
      col: undefined,
      on_condition_true: '',
    },
  },
};

/**
 * SourceGA4BigQueryFormAllConversions component for managing all conversions.
 */
const SourceGA4BigQueryFormAllConversions = () => {
  const { id, pipelineId } = useParams();

  // Go and get table schema for the form.
  const {
    pipelineData,
    tableSchema,
    isLoading: isLoadingTableSchema,
  } = useGA4TableSchema(pipelineId, 'ga4_base_events');

  // Get the form data
  const { data: formData, isLoading: isLoadingForm, error } = useGetDbtProjectFileQuery(id);

  // Extract conversion_events and default to an empty array
  const conversion_events = formData?.conversion_events || [];
  /**
   * Get actions and state with the actions.
   */
  const { emptyStateActions, handleAddConversion, handleDeleteConversion, isUpdating } =
    useGA4ConversionTemplates(id, conversion_events);

  // Update items to include the index
  const items = useMemo(() => {
    return conversion_events.map((prop, index) => ({
      id: index,
      name: prop.name,
      index,
    }));
  }, [conversion_events]);

  if (isLoadingForm || isUpdating) return <LoadingSpinner text="Loading conversions..." />;
  if (error) return <p>Error loading data</p>;

  return (
    <FormPropertyList
      items={items}
      emptyStateTitle="Add conversion"
      emptyStateDescription="Add a conversion event to get started."
      emptyStateActions={emptyStateActions}
      emptyProjectText="Add custom conversion"
      emptyProjectAction={() => handleAddConversion({ ...emptyConversion })}
      onDeleteItem={handleDeleteConversion}
      isLoading={isUpdating}
    >
      {(selectedItem) => (
        <SourceGA4BigQueryFormSingleConversionsNew
          id={id}
          pipelineId={pipelineId}
          pipelineData={pipelineData}
          tableSchema={tableSchema}
          isLoadingTableSchema={isLoadingTableSchema}
          selectedPropertyIndex={selectedItem.index}
          allProperties={conversion_events}
        />
      )}
    </FormPropertyList>
  );
};

export default SourceGA4BigQueryFormAllConversions;
