import { Decoration, ViewPlugin } from '@codemirror/view';
import { useMemo } from 'react';

/**
 * Custom hook for highlighting words in CodeMirror
 * @param {Object} highlightWords - Object where keys are categories and values are arrays of objects with value and label
 * @param {Object} styles - Styles object containing the highlight classes
 * @param {number|null} [errorLine=null] - Error line number (optional)
 * @param {number|null} [errorCharacter=null] - Error character position (optional)
 * @returns {Extension} CodeMirror extension for word highlighting
 */
function useHighlightedWordsInCodeMirror(
  highlightWords,
  styles,
  errorLine = null,
  errorCharacter = null
) {
  const highlightPlugin = useMemo(() => {
    return ViewPlugin.fromClass(
      class {
        constructor(view) {
          this.decorations = buildDecorations(view);
        }

        update(update) {
          if (update.docChanged || update.viewportChanged) {
            this.decorations = buildDecorations(update.view);
          }
        }
      },
      {
        decorations: (v) => v.decorations,
      }
    );

    function buildDecorations(view) {
      const decorations = [];
      const content = view.state.doc.toString();

      Object.entries(highlightWords).forEach(([key, words]) => {
        words.forEach((item) => {
          const word = typeof item === 'string' ? item : item.value;

          // Remove word boundaries for testing
          const regex = new RegExp(`\\b${word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`, 'gi');
          let match;
          let matchCount = 0;
          while ((match = regex.exec(content)) !== null) {
            matchCount += 1;
            const from = match.index;
            const to = from + match[0].length;

            decorations.push(
              Decoration.mark({ class: styles[`highlighted-word-${key}`] }).range(from, to)
            );
          }
        });
      });

      if (errorLine !== null && errorCharacter !== null) {
        const errorPos = view.state.doc.line(errorLine).from + errorCharacter - 1;
        decorations.push(
          Decoration.mark({ class: styles['error-location'] }).range(errorPos, errorPos + 1)
        );
      }

      return Decoration.set(decorations.sort((a, b) => a.from - b.from));
    }
  }, [highlightWords, errorLine, errorCharacter, styles]);

  return highlightPlugin;
}

export default useHighlightedWordsInCodeMirror;
