import { z } from 'zod';

export const sourceGA4BigQuerySchemaForm = z.object({
  id: z.number().optional(),
  name: z.string(),
  property_ids: z.array(z.string()).optional(),
  timezone: z.string(),
  ga4_export_type: z.enum(['daily', 'streaming']).optional(),
  using_ecommerce: z.boolean().optional(),
  bq_project_id: z.string(),
  bq_export_dataset_id: z.string(),
});

export const sourceGA4BigQuerySchemaList = z.object({
  id: z.number().optional(),
  bq_project_id: z.string(),
  bq_export_dataset_id: z.string(),
});

export const fieldNamesSourceGA4BigQuery = {
  id: { name: 'ID', description: 'The ID of the source' },
  name: { name: 'Name', description: 'The name of the source' },
  property_ids: { name: 'Property IDs', description: 'The property IDs of the source' },
  timezone: { name: 'Timezone', description: 'The timezone of the source' },
  ga4_export_type: { name: 'GA4 Export Type', description: 'The GA4 export type of the source' },
  using_ecommerce: { name: 'Using Ecommerce', description: 'The using ecommerce of the source' },
  bq_project_id: {
    name: 'BigQuery Project ID',
    description: 'Choose the BigQuery project',
  },
  bq_export_dataset_id: {
    name: 'BigQuery Export Dataset ID',
    description: 'The BigQuery export dataset ID of the source',
  },
};
