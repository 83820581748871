import PropTypes from 'prop-types';

/**
 * TextBubble component for displaying status labels or clickable fields
 * @param {Object} props - Component props
 * @param {'red' | 'green' | 'grey' | 'blue'} props.color - Color theme of the bubble
 * @param {string} props.text - Text to display in the bubble
 * @param {Function} [props.onClick] - Optional click handler
 * @param {React.ReactNode} [props.children] - Optional additional content
 * @param {string} [props.className] - Optional additional CSS classes
 */
const TextBubble = ({ color, text, onClick, children, className = '' }) => {
  const colorClasses = {
    red: 'bg-red-50 text-red-700 ring-red-600/20',
    green: 'bg-green-50 text-green-700 ring-green-600/20',
    grey: 'bg-gray-50 text-gray-700 ring-gray-600/20',
    blue: 'bg-blue-50 text-blue-700 ring-blue-600/20',
  };

  const baseClasses =
    'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset';
  const clickableClasses = onClick
    ? 'cursor-pointer hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
    : '';

  const handleKeyDown = (event) => {
    if (onClick && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      className={`${baseClasses} ${colorClasses[color]} ${clickableClasses} ${className}`}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      tabIndex={onClick ? 0 : undefined}
      role={onClick ? 'button' : undefined}
    >
      {text}
      {children}
    </span>
  );
};

TextBubble.propTypes = {
  color: PropTypes.oneOf(['red', 'green', 'grey', 'blue']).isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default TextBubble;
