import { z } from 'zod';

export const upgradeSourceGA4BigQuerySchemaForm = z.object({
  package_name: z.string().min(1, 'Package name is required'),
  revision: z.string().min(1, 'Version is required').default('v0.9.0-pipeline'),
});

export const upgradeSourceGA4BigQuerySchemaList = z.object({
  revision: z.string(),
});

export const fieldInfoUpgradeSourceGA4BigQuery = {
  package_name: {
    name: 'Package Name',
    description: 'The name of the package to upgrade',
  },
  revision: {
    name: 'Version',
    description: 'The version to upgrade to',
  },
};
