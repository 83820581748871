import { z } from 'zod';

import FormCheckbox from 'components/Forms/FormCheckbox/FormCheckbox';
import FormDatePicker from 'components/Forms/FormDatePicker/FormDatePicker';
import FormHourSelector from 'components/Forms/FormHourSelector/FormHourSelector';
import FormInput from 'components/Forms/FormInput/FormInput';
import FormSelect from 'components/Forms/FormSelect/FormSelect';
import FormTimezoneSelector from 'components/Forms/FormTimezoneSelector/FormTimezoneSelector';
import { toTitleCase } from 'utils/index';

export const findForeignKeyOptions = (foreignKeyOptions, path) => {
  // Handle null or undefined inputs
  if (!foreignKeyOptions || !path) {
    return null;
  }

  // console.log('foreignKeyOptions', foreignKeyOptions);
  // console.log('path', path);

  const match = foreignKeyOptions.find((option) => {
    if (!option || !Array.isArray(option.key) || option.key.length !== path.length) return false;

    return option.key.every((segment, index) => {
      if (segment === '*') return true;
      if (typeof path[index] === 'number' && segment === '*') return true;
      return JSON.stringify(segment) === JSON.stringify(path[index]);
    });
  });

  return match && Array.isArray(match.options) ? match.options : null;
};

export const isLoadingForeignKey = (loadingForeignKeys, path) => {
  return loadingForeignKeys.some(
    (loadingKey) => JSON.stringify(loadingKey) === JSON.stringify(path)
  );
};

export const combineSchemas = (schema1, schema2) => {
  return z.object({
    ...schema1.shape,
    ...schema2.shape,
  });
};

/**
 * Get the inner shape of a Zod schema, handling arrays, refinements, and optional types
 * @param {z.ZodTypeAny} schema - The Zod schema
 * @returns {z.ZodTypeAny} The inner type of the schema
 */
export const getSchemaFromZod = (schema) => {
  if (!schema || typeof schema !== 'object') {
    return {};
  }

  if (schema instanceof z.ZodObject) {
    return schema;
  }

  if (schema instanceof z.ZodEffects) {
    return getSchemaFromZod(schema.innerType());
  }

  if (schema instanceof z.ZodArray) {
    return schema;
  }

  if (schema instanceof z.ZodOptional || schema instanceof z.ZodNullable) {
    return getSchemaFromZod(schema.unwrap());
  }

  if (schema instanceof z.ZodDefault) {
    return getSchemaFromZod(schema.removeDefault());
  }

  // For other types, return the schema itself
  return schema;
};

export const getNestedError = (errors, path) => {
  return path.reduce((acc, key) => acc && acc[key], errors);
};

/**
 * Get the default value for a given Zod schema.
 * @param {z.ZodTypeAny} schema - The Zod schema
 * @returns {any} The default value for the schema
 */
export const getDefaultValue = (schema) => {
  // Handle ZodDefault
  if (schema instanceof z.ZodDefault) {
    // eslint-disable-next-line no-underscore-dangle
    return schema._def.defaultValue();
  }

  // Handle ZodEnum
  if (schema instanceof z.ZodEnum) {
    return schema.options[0];
  }

  // Handle other types
  if (schema instanceof z.ZodString) return '';
  if (schema instanceof z.ZodNumber) return 0;
  if (schema instanceof z.ZodBoolean) return false;
  if (schema instanceof z.ZodArray) return [];
  if (schema instanceof z.ZodObject) {
    return Object.fromEntries(
      Object.entries(schema.shape).map(([key, value]) => [key, getDefaultValue(value)])
    );
  }
  if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional) {
    return null;
  }

  // For unknown types, try to parse undefined
  try {
    return schema.parse(undefined);
  } catch {
    return undefined;
  }
};

/**
 * Get a border color based on the depth of nesting.
 * @param {number} depth - The depth of nesting
 * @returns {string} A Tailwind CSS color class
 */
export const getBorderColor = (depth) => {
  const colors = ['blue-500', 'green-500', 'yellow-500', 'red-500', 'purple-500'];
  return colors[depth % colors.length];
};

/**
 * Recursively unwraps Zod optional and array types to get the innermost type.
 *
 * @param {z.ZodTypeAny} schema - The Zod schema to unwrap
 * @returns {z.ZodTypeAny} The innermost type of the schema
 */
export const getZodInnerType = (schema) => {
  if (schema instanceof z.ZodOptional) {
    return getZodInnerType(schema.unwrap());
  }
  if (schema instanceof z.ZodArray) {
    return getZodInnerType(schema.element);
  }
  return schema;
};

// Add this function to extract default values from the Zod schema

// // Helper function to unwrap the type if it's optional
// export const getZodInnerType = (schema) => {
//   if (schema instanceof z.ZodOptional) {
//     // eslint-disable-next-line no-underscore-dangle
//     return schema._def.innerType;
//   }
//   return schema;
// };

// Utility function to convert date strings to Date objects
export const convertDatesInInitialData = (initialData, schema) => {
  // Check if schema and schema.shape exist
  if (!schema || !schema.shape) {
    return initialData;
  }

  return Object.keys(initialData).reduce((acc, key) => {
    // Only process fields that exist in the schema
    if (Object.prototype.hasOwnProperty.call(schema.shape, key)) {
      const value = initialData[key];
      const schemaType = getZodInnerType(schema.shape[key]);

      // Check if schemaType is ZodDate
      if (schemaType instanceof z.ZodDate && typeof value === 'string') {
        // Parse string to Date object, considering ISO 8601 format for compatibility
        const parsedDate = Date.parse(value);
        if (!isNaN(parsedDate)) {
          acc[key] = new Date(parsedDate);
        } else {
          console.warn(`Invalid date format for field '${key}':`, value);
          acc[key] = value; // Keep original value if parsing fails
        }
      } else {
        acc[key] = value;
      }
    }
    // Fields not in the schema are ignored

    return acc;
  }, {});
};

/**
 * Get field info for a specific path in the fieldInfo object.
 * @param {Object} fieldInfo - The field info object
 * @param {Array} path - The path to the desired field info
 * @returns {Object} The field info for the specified path
 */
export const getFieldInfo = (fieldInfo, path) => {
  return (
    path.reduce((acc, key) => {
      // Ignore array indices
      if (typeof key === 'number') return acc;

      if (acc && acc.fields && acc.fields[key]) {
        return acc.fields[key];
      }
      return acc && acc[key];
    }, fieldInfo) || {}
  );
};

export const generateFieldComponent = (
  fieldKey,
  label,
  value,
  register,
  control,
  errors,
  foreignKeyOptions,
  loadingForeignKeys,
  description,
  autoSetFields,
  fieldInfo,
  path
) => {
  let fieldComponent;

  const innerType = getZodInnerType(value);

  const isHidden = innerType.description && innerType.description.hidden;

  if (fieldKey === 'id') {
    return null;
  }

  const isForeignKey = findForeignKeyOptions(foreignKeyOptions, path) !== null;
  const isMulti =
    innerType instanceof z.ZodArray &&
    (innerType.element instanceof z.ZodString ||
      innerType.element instanceof z.ZodNumber ||
      innerType.element instanceof z.ZodBoolean);
  const isAutoSet = autoSetFields && autoSetFields[fieldKey] === true;

  const fieldError = getNestedError(errors, path);

  if (fieldKey.endsWith('timezone')) {
    fieldComponent = (
      <FormTimezoneSelector
        fieldKey={fieldKey}
        label={label}
        control={control}
        errors={fieldError}
        description={description}
        disabled={isAutoSet}
      />
    );
  } else if (fieldKey.endsWith('hour')) {
    fieldComponent = (
      <FormHourSelector
        fieldKey={fieldKey}
        label={label}
        control={control}
        errors={fieldError}
        description={description}
        disabled={isAutoSet}
      />
    );
  } else if (isForeignKey || isMulti) {
    fieldComponent = (
      <FormSelect
        fieldKey={fieldKey}
        label={label}
        control={control}
        errors={fieldError}
        options={
          isForeignKey
            ? findForeignKeyOptions(foreignKeyOptions, path)
            : innerType.element instanceof z.ZodEnum
            ? innerType.element.options.map((opt) => ({ label: opt, value: opt }))
            : []
        }
        toTitleCase={toTitleCase}
        isMulti={isMulti}
        description={description}
        isLoading={isForeignKey ? isLoadingForeignKey(loadingForeignKeys, path) : false}
        disabled={isAutoSet}
      />
    );
  } else if (innerType instanceof z.ZodBoolean) {
    fieldComponent = (
      <FormCheckbox
        fieldKey={fieldKey}
        label={label}
        register={register}
        errors={fieldError}
        description={description}
        disabled={isAutoSet}
      />
    );
  } else if (innerType instanceof z.ZodNumber) {
    fieldComponent = (
      <FormInput
        fieldKey={fieldKey}
        label={label}
        register={register}
        errors={fieldError}
        toTitleCase={toTitleCase}
        type="number"
        description={description}
        disabled={isAutoSet}
      />
    );
  } else if (innerType instanceof z.ZodEnum) {
    const enumOptions = innerType.options.map((optionValue) => ({
      label: innerType.description?.[optionValue] || optionValue,
      value: optionValue,
    }));

    fieldComponent = (
      <FormSelect
        fieldKey={fieldKey}
        label={label}
        control={control}
        errors={fieldError}
        options={enumOptions}
        toTitleCase={toTitleCase}
        isMulti={isMulti}
        disabled={isAutoSet}
      />
    );
  } else if (fieldKey.endsWith('_date')) {
    fieldComponent = (
      <FormDatePicker
        fieldKey={fieldKey}
        label={label}
        control={control}
        errors={fieldError}
        toTitleCase={toTitleCase}
        disabled={isAutoSet}
      />
    );
  } else {
    fieldComponent = (
      <FormInput
        fieldKey={fieldKey}
        label={label}
        register={register}
        errors={fieldError}
        toTitleCase={toTitleCase}
        disabled={isAutoSet}
      />
    );
  }

  // Wrap the fieldComponent with a hidden div if necessary
  return isHidden ? <div className="hidden">{fieldComponent}</div> : fieldComponent;
};
