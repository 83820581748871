/**
 * Displays current subscription details and management options using ListStacked component
 */
import { format } from 'date-fns';
import { useRef, useState } from 'react';

import Alert from 'components/Alert/Alert';
import ListStacked from 'components/ListStacked/ListStacked';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useCancelPaddleSubscription } from 'hooks/paddle/useCancelPaddleSubscription';
import { useChangeSubscriptionItemMutation, useGetPaddleSubscriptionsQuery } from 'services/rtkApi';

import CancelPlanModal from './CancelPlanModal';
import PricingTableUpgrade from './PricingTables/PricingTableUpgrade';
import { FEATURE_SECTIONS, PRICING_TIERS } from './pricingV1Constants';

export default function ManageYourPlan() {
  // Track subscription being modified and item being cancelled
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  // Modal and view states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChangingPlan, setIsChangingPlan] = useState(false);

  // Tracks loading states and success/error messages
  const messageRef = useRef({ message: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);

  // API hooks for fetching and modifying subscriptions
  const {
    data: subscriptions,
    isLoading: isLoadingSubscriptions,
    error,
    refetch,
  } = useGetPaddleSubscriptionsQuery();
  const [changeSubscriptionItem] = useChangeSubscriptionItemMutation();
  const { removeSubscriptionItems, isLoading: isUpdating } = useCancelPaddleSubscription();

  // Handles completion of any subscription action (upgrade, cancel, etc)
  const handleActionComplete = async (message) => {
    setIsLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await refetch();

    setIsChangingPlan(false);
    setIsLoading(false);
    messageRef.current = { message, type: 'success' };
  };

  // Initiates plan change flow
  const handleChangePlan = async (subscription) => {
    setIsLoading(true);
    setSelectedSubscription(subscription);
    setIsChangingPlan(true);
  };

  // Formats price display with currency
  const formatPrice = (amount, currency) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency,
    }).format(amount / 100);
  };

  // Opens cancel modal for specific subscription item
  const handleRemoveItem = (subscription, item) => {
    setSelectedSubscription(subscription);
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  // Resets state when cancel modal is closed
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedSubscription(null);
    setSelectedItem(null);
  };

  // Formats subscription items for ListStacked component
  const getListItems = (subscription) => {
    return subscription.items.map((item) => ({
      id: item.price.id,
      name: `${item.price.name} - ${formatPrice(
        item.price.unit_price.amount,
        item.price.unit_price.currency_code
      )}`,
      description: item.product.name,
      originalItem: item,
    }));
  };

  // Loading and error states
  if (isLoadingSubscriptions) return <LoadingSpinner text="Loading subscription details..." />;
  if (error) return <div>Error loading subscription</div>;

  // Show plan change view when upgrading
  if (isChangingPlan && selectedSubscription) {
    return (
      <div>
        {/* Back link */}
        <div className="mb-4">
          <a
            href="#"
            className="text-gray-600 hover:text-gray-900"
            onClick={(e) => {
              e.preventDefault();
              setIsChangingPlan(false);
              setIsLoading(false);
            }}
          >
            ← Back to Subscription
          </a>
        </div>

        <PricingTableUpgrade
          initialTiers={PRICING_TIERS}
          sections={FEATURE_SECTIONS}
          existingSubscription={selectedSubscription}
          changeSubscriptionItem={async (newPlan) => {
            await changeSubscriptionItem(newPlan);
            handleActionComplete('Your plan has been updated successfully');
          }}
        />
      </div>
    );
  }

  // Action buttons for each subscription
  const buttons = [
    {
      label: 'Change Plan',
      variant: 'primary',
      onClick: (item, subscription) => handleChangePlan(subscription),
    },
    {
      label: 'Cancel',
      variant: 'danger',
      isOutline: true,
      onClick: (item, subscription) => handleRemoveItem(subscription, item.originalItem),
    },
  ];

  // Main render with loading states, messages, and subscription list
  return (
    <>
      {isLoading && (
        <div className="flex justify-center py-8">
          <LoadingSpinner text="Updating subscription details..." size={12} isFullWidth />
        </div>
      )}

      {!isLoading && messageRef.current.message && (
        <div className="mb-8 w-full">
          <Alert
            status={messageRef.current.type === 'success' ? 'success' : 'error'}
            message={messageRef.current.message}
          />
        </div>
      )}

      {!isLoading && (
        <div className="space-y-10 divide-y divide-gray-900/10 w-full">
          <div className="grid grid-cols-1 gap-6 max-w-2xl">
            {subscriptions?.results.map((subscription) => (
              <ListStacked
                key={subscription.id}
                header={{
                  title: 'Subscription',
                  description: `Next billing date: ${format(
                    new Date(subscription.items[0].next_billed_at),
                    'MMM dd, yyyy'
                  )}`,
                }}
                items={getListItems(subscription)}
                buttons={buttons}
                buttonContext={subscription}
                disabled={isUpdating}
              />
            ))}
          </div>
        </div>
      )}

      {/* Cancel confirmation modal */}
      <CancelPlanModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        subscription={selectedSubscription}
        itemToRemove={selectedItem}
        onSuccess={() => handleActionComplete('Your plan has been cancelled successfully')}
      />
    </>
  );
}
