import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Button from 'components/Button/Button'; // Import your Button component
import FormInput from 'components/Forms/FormInput/FormInput'; // Import your FormInput component
import LoggedOutLegalLinks from 'components/LegalLinks/LoggedOutLegalLinks';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'; // Import the LoadingSpinner component
import SignedOutContainer from 'components/SignedOutContainer/SignedOutContainer';
import { LOGIN, SOCIAL_LOGIN_REDIRECT } from 'routes/constants';
import {
  getSocialRedirectUrlRequest,
  registerRequest,
  resetRegisterSuccess,
} from 'services/auth/authSlice';
import { clearFormError } from 'services/errors/errorSlice';
import LoggedOutLayout from 'templates/LoggedOutLayout';

const Register = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const registerSuccess = useSelector((state) => state.auth.registerSuccess);
  const formErrors = useSelector((state) => state.error.formErrors); // Get formErrors from Redux state
  const registerErrors = formErrors?.register || []; // Extract register form errors
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const handleGoogleRegister = () => {
    const provider = 'google-oauth2';
    const redirect_uri = `https://localhost:8000${SOCIAL_LOGIN_REDIRECT}`;
    dispatch(clearFormError('register'));
    dispatch(getSocialRedirectUrlRequest({ provider, redirect_uri }));
  };

  useEffect(() => {
    if (registerSuccess) {
      setShouldRedirect(true);
    }
  }, [registerSuccess]);

  useEffect(() => {
    if (shouldRedirect) {
      // navigate('/login'); // Redirect to login after successful registration
      setShouldRedirect(false);
      dispatch(resetRegisterSuccess());
    }
  }, [shouldRedirect, navigate, dispatch]);

  useEffect(() => {
    if (formErrors?.register?.length > 0) {
      setIsLoading(false);
    }
  }, [formErrors]);

  const onSubmit = (data) => {
    setIsLoading(true);
    dispatch(clearFormError('register'));
    dispatch(registerRequest(data));
  };

  console.log(formErrors);

  return (
    <LoggedOutLayout>
      <SignedOutContainer>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Create your account
            </h2>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6"
          >
            <FormInput
              fieldKey="email"
              fieldName="email"
              placeholder="e.g. john@example.com"
              register={() => register('email', { required: 'Email is required' })}
              errors={errors.email}
            />
            <FormInput
              fieldKey="name"
              fieldName="Name"
              placeholder="e.g. John Smith"
              register={() => register('name', { required: 'Name is required' })}
              errors={errors.name}
            />
            <FormInput
              fieldKey="account"
              fieldName="Company"
              placeholder="e.g. My Company, John's Personal Account"
              register={() => register('account', { required: 'Company name is required' })}
              errors={errors.account}
            />
            <FormInput
              fieldKey="password"
              fieldName="password"
              placeholder="Password"
              type="password"
              register={() => register('password', { required: 'Password is required' })}
              errors={errors.password}
            />
            <FormInput
              fieldKey="confirmPassword"
              fieldName="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              register={() =>
                register('confirmPassword', { required: 'Please confirm your password' })
              }
              errors={errors.confirmPassword}
            />
            {registerErrors.length > 0 && (
              <div className="bg-red-100 border border-red-400 text-red-700 rounded p-4">
                {registerErrors.map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
              </div>
            )}
            {isLoading ? (
              <LoadingSpinner text="Registering..." size="8" />
            ) : (
              <>
                <Button type="submit" variant="primary" className="w-full">
                  Register
                </Button>
                <div className="mt-6 text-center">
                  <p className="text-sm text-gray-500 pb-0">Or continue with:</p>
                </div>
                <div className="mt-6 grid">
                  <a
                    href="#"
                    onClick={handleGoogleRegister}
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                  >
                    <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                      <path
                        d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                        fill="#EA4335"
                      />
                      <path
                        d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                        fill="#4285F4"
                      />
                      <path
                        d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                        fill="#34A853"
                      />
                    </svg>
                    <span className="text-sm font-semibold leading-6">Google</span>
                  </a>
                </div>
              </>
            )}
            <div className="mt-6">
              <Link
                to={LOGIN}
                className="text-sm font-semibold leading-6 text-gray-600 hover:text-gray-900"
              >
                Already have an account? Login
              </Link>
            </div>
            <LoggedOutLegalLinks />
          </form>
        </div>
      </SignedOutContainer>
    </LoggedOutLayout>
  );
};

export default Register;
