// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zKx8zXB9nqHmJcJKZt5w{background-color:#006f71;padding:1px 4px;color:#fff;border-radius:2px;border:1px solid #00b6b2}.zKx8zXB9nqHmJcJKZt5w span{color:#fff}.NKwN0SZYmnoz9tpbfZ_4{background-color:#f0c894;padding:1px 4px;color:#000;border-radius:2px;border:1px solid #84470f}.NKwN0SZYmnoz9tpbfZ_4 span{color:#000}.PZfGju0H9bkqXJUpyL_l{background-color:#006f71;padding:2px 0px}.cZgJsa91IoRUjc8cKupA{background-color:#006f71;padding:2px 0px}.DV7ft8cwmX6PcPiVRCHM{background-color:red;padding:2px 0px}`, "",{"version":3,"sources":["webpack://./frontend/js/components/JsonEditor/JsonEditor.module.scss"],"names":[],"mappings":"AAAA,sBACE,wBAAA,CACA,eAAA,CACA,UAAA,CACA,iBAAA,CACA,wBAAA,CAGF,2BACE,UAAA,CAGF,sBACE,wBAAA,CACA,eAAA,CACA,UAAA,CACA,iBAAA,CACA,wBAAA,CAGF,2BACE,UAAA,CAGF,sBACE,wBAAA,CACA,eAAA,CAGF,sBACE,wBAAA,CACA,eAAA,CAGF,sBACE,oBAAA,CACA,eAAA","sourcesContent":[".highlighted-word-columns {\n  background-color: #006f71;\n  padding: 1px 4px;\n  color: #ffffff;\n  border-radius: 2px;\n  border: 1px solid #00b6b2;\n}\n\n.highlighted-word-columns span {\n  color: #fff;\n}\n\n.highlighted-word-operators {\n  background-color: #f0c894;\n  padding: 1px 4px;\n  color: #000000;\n  border-radius: 2px;\n  border: 1px solid #84470f;\n}\n\n.highlighted-word-operators span {\n  color: #000000;\n}\n\n.highlighted-word-in-braces {\n  background-color: #006f71;\n  padding: 2px 0px;\n}\n\n.highlighted-space {\n  background-color: #006f71;\n  padding: 2px 0px;\n}\n\n.error-location {\n  background-color: #ff0000;\n  padding: 2px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlighted-word-columns": `zKx8zXB9nqHmJcJKZt5w`,
	"highlighted-word-operators": `NKwN0SZYmnoz9tpbfZ_4`,
	"highlighted-word-in-braces": `PZfGju0H9bkqXJUpyL_l`,
	"highlighted-space": `cZgJsa91IoRUjc8cKupA`,
	"error-location": `DV7ft8cwmX6PcPiVRCHM`
};
export default ___CSS_LOADER_EXPORT___;
