/**
 * Main subscription management page that conditionally renders either
 * ChooseYourPlan or ManageYourPlan based on subscription status
 */

import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import { useGetPaddleSubscriptionsQuery } from 'services/rtkApi';
import PageLayout from 'templates/PageLayout';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

import ChooseYourPlan from './components/ChooseYourPlan';
import ManageYourPlan from './components/ManageYourPlan';

export default function PlanManagementPage() {
  const { data: subscriptions, isLoading } = useGetPaddleSubscriptionsQuery();
  const hasActiveSubscription = subscriptions?.results?.length > 0;

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">
            {hasActiveSubscription ? 'Manage Your Plan' : 'Choose Your Plan'}
          </h1>
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip hasFlex={false}>
          {isLoading ? (
            <div>Loading...</div>
          ) : hasActiveSubscription ? (
            <ManageYourPlan />
          ) : (
            <ChooseYourPlan />
          )}
        </PageLayoutInnerStrip>
      </div>
    </PageLayout>
  );
}
