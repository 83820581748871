import Alert from 'components/Alert/Alert'; // Changed from AlertList/AlertList

/**
 * Renders a list of alerts
 * @param {Object} props
 * @param {Array<{type: string, message: string, linkText?: string, linkHref?: string}>} props.alerts - Alerts to display
 */
const AlertList = ({ alerts = [] }) => {
  return (
    <div className="space-y-4 mt-4 mb-4">
      {alerts.map((alert, index) => (
        <Alert
          key={index}
          status={alert.type} // Changed from hardcoded "error" to use alert.type
          message={alert.message}
          linkText={alert.linkText}
          linkHref={alert.linkHref}
        />
      ))}
    </div>
  );
};

export default AlertList;
