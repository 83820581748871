import PropTypes from 'prop-types';
import React from 'react';

/**
 * Title component for displaying a styled h1 element.
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - The content to be displayed in the title
 */
const Title = ({ children }) => {
  return <h1 className="text-xl font-semibold leading-7 text-gray-900">{children}</h1>;
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Title;
