import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { removeGlobalError } from 'services/errors/errorSlice';

const ErrorToaster = () => {
  const errors = useSelector((state) => state.error?.errors || []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errors?.length > 0) {
      toast.error(

        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            navigator.clipboard.writeText(errors[0]);
            toast.info('Copied to clipboard!', { autoClose: 2000 });
          }}
          style={{ cursor: 'pointer' }}
        >
          {errors[0]}
        </div>,
        {
          onClose: () => dispatch(removeGlobalError()),
        }
      );
    }
  }, [errors, dispatch]);

  return ReactDOM.createPortal(
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
    />,
    document.body
  );
};

export default ErrorToaster;
