import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import AvailableValuesForm from 'components/AvailableValuesForm/AvailableValuesForm';
import Button from 'components/Button/Button';
import JsonTemplates from 'components/Forms/JsonTemplates/JsonTemplates';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import useButtonStatus from 'hooks/useButtonStatus';

/**
 * GenericJsonForm component for editing data from an RTK Query hook
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the form
 * @param {Object} props.data - Data from the RTK Query hook
 * @param {Function} props.updateMutation - RTK Query update mutation hook
 * @param {Object} [props.zodSchema] - Optional Zod schema for validation
 * @param {Array} [props.templates] - Optional array of template objects
 * @param {Object} [props.availableValues={}] - Dictionary of available values categories
 * @param {Function} [props.createUpdatePayload] - Function to create the update payload
 */
function GenericJsonForm({
  id,
  data,
  updateMutation,
  zodSchema,
  templates = [],
  availableValues = {},
  createUpdatePayload = (id, parsedData) => ({ id, ...parsedData }),
}) {
  const [updateData, { isLoading }] = updateMutation();

  const [buttonStatus, setButtonStatus] = useButtonStatus();
  const [editedData, setEditedData] = useState(
    typeof data === 'string' ? data : JSON.stringify(data, null, 2)
  );
  const [isJsonValid, setIsJsonValid] = useState(true);
  const [isSchemaValid, setIsSchemaValid] = useState(true);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [copiedValue, setCopiedValue] = useState(null);

  const handleJsonChange = (updatedData) => {
    setEditedData(updatedData);
  };

  const handleValidationChange = (isValidJson, isValidSchema) => {
    setIsJsonValid(isValidJson);
    setIsSchemaValid(isValidSchema);
  };

  const handleSave = async () => {
    if (!isJsonValid || !isSchemaValid) return;
    setButtonStatus('loading');
    try {
      const parsedData = JSON.parse(editedData);

      const updatePayload = createUpdatePayload(id, parsedData);

      await updateData(updatePayload);
      setButtonStatus('updated');
    } catch (error) {
      console.error('Error updating data:', error);
      setButtonStatus('failed');

      // Dispatch API error
      if (error.response) {
        dispatch({
          type: 'API_ERROR',
          payload: {
            status: error.response.status,
            data: error.response.data,
          },
        });
      }
    }
  };

  console.log('editedData', editedData);

  const handleAddTemplate = (templateData) => {
    if (!isJsonValid) {
      setError('Current data must be valid JSON before adding a template.');
      return;
    }

    try {
      const currentData = JSON.parse(editedData);
      let newData;
      if (Array.isArray(currentData)) {
        newData = [...currentData, templateData];
      } else if (typeof currentData === 'object' && currentData !== null) {
        newData = { ...currentData, ...templateData };
      } else {
        throw new Error('Cannot add template to non-object and non-array data.');
      }
      setEditedData(JSON.stringify(newData, null, 2));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCopyValue = (value) => {
    navigator.clipboard.writeText(value);
    setCopiedValue(value);
    setTimeout(() => setCopiedValue(null), 2000);
  };

  return (
    <div>
      {(templates.length > 0 || Object.keys(availableValues).length > 0) && (
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            {templates.length > 0 && (
              <JsonTemplates onAddTemplate={handleAddTemplate} templates={templates} />
            )}
          </div>
          <div className="space-y-4">
            {Object.entries(availableValues).map(([category, values]) => (
              <AvailableValuesForm
                key={category}
                category={category}
                valueType={category}
                valuesObject={values}
              />
            ))}
          </div>
        </div>
      )}

      {error && <div className="text-red-500 mb-2">{error}</div>}
      <JsonEditor
        data={editedData}
        onDataChange={handleJsonChange}
        onValidationChange={handleValidationChange}
        zodSchema={zodSchema}
        highlightWords={availableValues}
      />
      <div className="mt-4">
        <Button
          loading={isLoading}
          onClick={handleSave}
          variant="primary"
          disabled={!isJsonValid || !isSchemaValid}
          status={buttonStatus}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

GenericJsonForm.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  updateMutation: PropTypes.func.isRequired,
  zodSchema: PropTypes.object,
  templates: PropTypes.arrayOf(PropTypes.object),
  availableValues: PropTypes.objectOf(PropTypes.object),
  createUpdatePayload: PropTypes.func,
};

export default GenericJsonForm;
