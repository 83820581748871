import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import GA4PropertyFormChecker from 'components/_forms/ga4_properties/GA4PropertyFormChecker';
import CrudForm from 'components/Forms/CrudForm/CrudForm';
import {
  autoSetFields,
  bigqueryDatasetSchemaForm,
  fieldInfoBigQueryDataset,
} from 'schemas/bigqueryDatasetSchema';
import {
  useCreateBigQueryDatasetMutation,
  useGetGoogleBigQueryProjectsQuery,
} from 'services/rtkApi';

const BigQueryDatasetCreate = ({ gcloud_project_id }) => {
  const { data: projectsData, isLoading: isLoadingProjects } = useGetGoogleBigQueryProjectsQuery();

  // Updated foreignKeyOptions to match the new format
  const foreignKeyOptions = useMemo(
    () => [
      {
        key: ['gcloud_project_id'],
        options: projectsData?.map((project) => ({ value: project.id, label: project.name })) || [],
      },
    ],
    [projectsData]
  );

  // Updated loadingForeignKeys to match the new format
  const loadingForeignKeys = useMemo(
    () => (isLoadingProjects ? [['gcloud_project_id']] : []),
    [isLoadingProjects]
  );

  const initialValues = useMemo(
    () => ({
      gcloud_project_id,
    }),
    [gcloud_project_id]
  );

  const handleFieldChange = useCallback((formValues, changedFields) => {
    // Add any specific field change logic here if needed
  }, []);

  return (
    <div>
      <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-4">
        <h3 className=" mb-4">
          The dataset you use has to be in the same location as the GA4 property export. Find our
          your export location below and use it to create your dataset.
        </h3>
        <GA4PropertyFormChecker />
      </div>
      <CrudForm
        formType="create"
        mutationHook={useCreateBigQueryDatasetMutation}
        schema={bigqueryDatasetSchemaForm}
        fieldInfo={fieldInfoBigQueryDataset}
        foreignKeyOptions={foreignKeyOptions}
        loadingForeignKeys={loadingForeignKeys}
        autoSetFields={autoSetFields}
        initialValues={initialValues}
        tabs={[]}
        showTitleAndDescriptionColumn={false}
        onFieldChange={handleFieldChange}
      />
    </div>
  );
};

BigQueryDatasetCreate.propTypes = {
  gcloud_project_id: PropTypes.string,
};

BigQueryDatasetCreate.defaultProps = {
  gcloud_project_id: '',
};

export default BigQueryDatasetCreate;
