import { get } from 'lodash';
import { useCallback } from 'react';

/**
 * Hook for handling dynamic field updates based on other field values.
 * @param {Object[]} config - Configuration objects for dynamic field updates.
 * @param {Object} tableSchema - Schema of the table (optional).
 * @returns {Function} updateDynamicFields - Function to update dynamic fields.
 */
const useDynamicFieldUpdates = (config, tableSchema = null) => {
  return useCallback(
    (formValues, changedFields, changedPaths) => {
      const updates = [];

      config.forEach(({ sourcePath, targetPath, updateFunction }) => {
        changedPaths.forEach((changedPath) => {
          if (isPathMatch(changedPath.path, sourcePath)) {
            const sourceValue = get(formValues, changedPath.path);
            const newValue = updateFunction(sourceValue, formValues, tableSchema);
            const fullTargetPath = getFullPath(changedPath.path, sourcePath, targetPath);

            updates.push({ path: fullTargetPath.join('.'), value: newValue });
          }
        });
      });

      return updates;
    },
    [config, tableSchema]
  );
};

/**
 * Checks if a changed path matches a config path, allowing for wildcards.
 * @param {string[]} changedPath - The path that changed.
 * @param {string[]} configPath - The path from the config, potentially with wildcards.
 * @returns {boolean} True if the paths match, false otherwise.
 */
const isPathMatch = (changedPath, configPath) => {
  if (configPath.length !== changedPath.length) return false;
  return configPath.every((segment, index) => segment === '*' || segment === changedPath[index]);
};

/**
 * Generates the full target path based on the changed path and config paths.
 * @param {string[]} changedPath - The path that changed.
 * @param {string[]} sourcePath - The source path from the config.
 * @param {string[]} targetPath - The target path from the config.
 * @returns {string[]} The full target path.
 */
const getFullPath = (changedPath, sourcePath, targetPath) => {
  return targetPath.map((segment, index) =>
    segment === '*' ? changedPath[sourcePath.indexOf('*', index)] : segment
  );
};

export default useDynamicFieldUpdates;
