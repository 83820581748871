import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import EmptyState from 'components/EmptyState/EmptyState'; // Add this import
import FlexListTable from 'components/FlexListTable/FlexListTable'; // Adjust the import path
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'; // Add this import
import TextBubble from 'components/TextBubble/TextBubble'; // Add this import
import { PIPELINE_ALL_SOURCE_LIST, PIPELINE_CREATE_FORM } from 'routes/constants';
import { useGetPipelinesQuery } from 'services/rtkApi'; // Adjust the import path

const PipelineTable = () => {
  const location = useLocation();
  const { data, error, isLoading, isFetching, refetch } = useGetPipelinesQuery();

  useEffect(() => {
    refetch();
  }, [location, refetch]);

  // Optional: Validate or parse data with Zod (if needed)
  // eslint-disable-next-line no-unused-vars
  // const validatedData =
  //   data && data.results.length > 0 ? pipelineSchemaList.parse(data.results[0]) : [];

  const columns = React.useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      {
        Header: 'Location',
        accessor: (row) => `${row.gcloud_project_id}.${row.bq_output_dataset}`,
        id: 'location',
      },
      {
        Header: 'Currently Running',
        accessor: 'currently_running',
        Cell: ({ value }) => (value ?? false).toString(),
      },
      {
        Header: 'Pipeline Status',
        accessor: 'pipeline_status',
        Cell: ({ value }) => {
          let color = 'grey';
          const text = value || 'Not run';

          if (value === 'complete') {
            color = 'green';
          } else if (value && value !== 'Not run') {
            color = 'red';
          }

          return <TextBubble color={color} text={text} />;
        },
      },
      { Header: 'Last Completed Run Status', accessor: 'last_completed_run_status' },
      // Add more columns as needed
    ],
    []
  );

  const linkButtons = [
    {
      label: 'Edit Pipeline',
      link: PIPELINE_ALL_SOURCE_LIST,
    },
  ];

  if (isLoading) return <LoadingSpinner text="Loading pipelines..." />;
  if (error) return <div>Error loading pipelines</div>;
  if (isFetching) return <LoadingSpinner text="Updating pipelines..." />;

  // Check if data is empty
  if (!data || !data.results || data.results.length === 0) {
    return (
      <EmptyState
        title="No pipelines found"
        description="Get started by creating a new pipeline."
        buttonText="New Pipeline"
        buttonAction={PIPELINE_CREATE_FORM}
      />
    );
  }

  return (
    <div className="mt-4">
      <FlexListTable
        columns={columns}
        data={data.results || []}
        hiddenColumnIds={['id']}
        linkButtons={linkButtons}
      />
      {isFetching && <LoadingSpinner text="Refreshing..." />}
    </div>
  );
};

export default PipelineTable;
