import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SourceGA4BigQueryFormStageColumnsToUnnest from 'components/_forms/source_ga4_bigquery_exports/ColumnsToUnnest/SourceGA4BigQueryFormStageColumnsToUnnest';
import { FormStateProviderColumnsToUnnest } from 'components/_forms/source_ga4_bigquery_exports/ColumnsToUnnest/SourceGA4BigQueryFormStateProvidersColumnsToUnnest';
import SourceGA4EventSample from 'components/_forms/source_ga4_bigquery_exports/ColumnsToUnnest/SourceGA4EventSampleColumnsToUnnest';
import SourceGA4BigQueryFormActionButtons from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryFormActionButtons';
import SourceGA4BigQueryFormNavigation from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryFormNavigation';
import PageAccordionStrip from 'components/PageAccordionStrip/PageAccordionStrip';
import { useGetPipelineByIdQuery, useGetSourceGA4BigQueryByIdQuery } from 'services/rtkApi';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const SourceGA4BigQueryFormPage = () => {
  const { id, pipelineId } = useParams();
  const isUpdate = Boolean(id);
  const pageTitle = isUpdate ? 'Update GA4 Pipeline' : 'Create GA4 Pipeline';

  const [propertyId, setPropertyId] = useState(null);
  const [propertyOptions, setPropertyOptions] = useState([]);

  const { data: sourceData } = useGetSourceGA4BigQueryByIdQuery(id, { skip: !isUpdate });
  const { data: pipelineData } = useGetPipelineByIdQuery(pipelineId, { skip: !pipelineId });

  useEffect(() => {
    if (sourceData) {
      const options = sourceData.property_ids.map((id) => ({ value: id, label: id }));
      setPropertyOptions(options);
      setPropertyId(sourceData.property_ids[0]);
    }
  }, [sourceData]);

  // If we need to fuck with form data across different levels then we can use FormStateProvider
  // to allow other elements to access the form data without having to pass through.

  const handleSuccess = useCallback(() => {
    // Handle what happens after successful form submission
  }, []);

  return (
    <PageLayout isItGrey>
      <FormStateProviderColumnsToUnnest pipelineId={pipelineId}>
        <div>
          <PageLayoutInnerStrip>
            <h1 className="text-xl font-semibold leading-7 text-gray-900">{pageTitle}</h1>
            {isUpdate && (
              <SourceGA4BigQueryFormActionButtons
                pipelineId={pipelineId}
                sourceGA4BigQueryID={id}
              />
            )}
          </PageLayoutInnerStrip>
          <PageLayoutInner>
            <div className="flex">
              <SourceGA4BigQueryFormNavigation isUpdate={isUpdate} activeTab="main" />
              <div className="flex-grow mt-5">
                <div className="text-gray-600 text-sm">
                  <p>
                    There is a lot of useful data in GA4. Here we&apos;re going to extract any
                    information we want from our event parameters.
                  </p>
                  <p>
                    If you want a piece of data for a conversion, session property or anything else
                    you need to take it here! (Although don&apos;t worry you can come back.)
                  </p>
                  <p>
                    You&apos;ll see a sampling of all the available options below the form. Add any
                    parameters you&apos;re interested in to the form.
                  </p>
                  <p>
                    Once you&apos;ve done this you&apos;ll need to &quot;Run initial data
                    setup&quot;. This might take up to 30 minutes depending on the size of your GA4
                    propery.
                  </p>
                </div>
                <SourceGA4BigQueryFormStageColumnsToUnnest
                  entityId={id}
                  onSuccess={handleSuccess}
                  propertyId={propertyId}
                />
                <PageAccordionStrip title="GA4 Event Data" className="mt-4">
                  <SourceGA4EventSample
                    property_id={propertyId}
                    project_id={sourceData?.gcloud_project_id}
                    propertyOptions={propertyOptions}
                    onPropertyChange={setPropertyId}
                  />
                </PageAccordionStrip>
              </div>
            </div>
          </PageLayoutInner>
        </div>
      </FormStateProviderColumnsToUnnest>
    </PageLayout>
  );
};

export default SourceGA4BigQueryFormPage;
