import Button from 'components/Button/Button';

/**
 * JsonTemplates component for adding predefined JSON templates
 * @param {Object} props - Component props
 * @param {Function} props.onAddTemplate - Callback function to add a template
 * @param {Array} props.templates - Array of template objects
 */
const JsonTemplates = ({ onAddTemplate, templates }) => {
  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Templates</h3>
      <div className="space-y-2">
        {templates.map((template, index) => (
          <div key={index} className="grid grid-cols-3 gap-4 items-center">
            <Button
              onClick={() => onAddTemplate(template.data)}
              variant="secondary"
              className="w-full"
            >
              Add {template.name}
            </Button>
            <div className="col-span-2 text-sm text-gray-600">{template.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JsonTemplates;
