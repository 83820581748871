import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button/Button';
import CommonActionSingle from 'components/CommonActionSingle/CommonActionSingle';
import FormSelect from 'components/Forms/FormSelect/FormSelect';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import TextBubble from 'components/TextBubble/TextBubble';
import { GOOGLE_API_PERMISSIONS_REGISTER } from 'routes/constants';
import { getGoogleAPIAuthStatus } from 'services/auth/auth'; // Import the function
import { useGetGoogleBigQueryProjectsQuery } from 'services/rtkApi';
import { setGCloudProjectId } from 'services/user/userSlice';
/**
 * GoogleProjectSwitcher component for selecting a Google BigQuery project.
 * @returns {JSX.Element} The rendered component
 */
export default function GoogleProjectSwitcher() {
  const dispatch = useDispatch();
  const gcloud_project_id = useSelector((state) => state.user.gcloud_project_id);
  const { control, setValue, watch } = useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const googleAPIAuthStatus = useSelector((state) => state.auth.googleAPIAuthStatus);
  const isAuthorized = googleAPIAuthStatus;

  // Only call the query when we have permission
  const {
    data: projects,
    isLoading,
    error,
  } = useGetGoogleBigQueryProjectsQuery(undefined, {
    skip: !isAuthorized,
  });

  const watchedProject = watch('project');

  useEffect(() => {
    const checkPermissions = async () => {
      const permissionStatus = await getGoogleAPIAuthStatus();
      setHasPermission(permissionStatus);
    };

    checkPermissions();
  }, []);

  // Move project initialization to separate effect that runs only after we have projects
  useEffect(() => {
    if (!projects || !hasPermission) return;

    if (!gcloud_project_id && projects.length > 0) {
      setValue('project', projects[0].id);
    } else if (gcloud_project_id) {
      setValue('project', gcloud_project_id);
    }
  }, [projects, gcloud_project_id, setValue, hasPermission]);

  /**
   * Handles project selection change
   */
  const handleProjectChange = () => {
    dispatch(setGCloudProjectId(watchedProject));
    setIsSuccess(true);
    setTimeout(() => setIsSuccess(false), 1000);
  };

  if (error) return <div>Error loading projects: {error.message}</div>;

  if (isLoading) {
    return (
      <div className="bg-white shadow sm:rounded-lg mb-6">
        <div className="px-4 py-5 sm:p-6">
          <LoadingSpinner text="Loading Google Projects..." />
        </div>
      </div>
    );
  }

  if (!isAuthorized) {
    return (
      <CommonActionSingle
        title="Google BigQuery Permissions Required"
        description="In order to select your project you need to authorize Pipeline access."
        buttonName="Set Up Permissions"
        href={GOOGLE_API_PERMISSIONS_REGISTER}
        className="mb-6"
      />
    );
  }

  console.log('watchedProject', watchedProject);
  console.log('gcloud_project_id', gcloud_project_id);

  return (
    <div className="bg-white shadow sm:rounded-lg mb-6">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Select Google Project</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Choose a Google BigQuery project as your default.</p>
        </div>
        <div className="">
          {hasPermission ? (
            <>
              <FormSelect
                fieldKey="project"
                label="Google Project"
                control={control}
                errors={{}}
                options={
                  projects?.map((project) => ({ value: project.id, label: project.name })) || []
                }
                isLoading={isLoading}
                className="w-full max-w-xs mb-4"
              />
              {watchedProject !== gcloud_project_id && (
                <Button onClick={handleProjectChange} loading={isLoading} variant="primary">
                  Confirm Selection
                </Button>
              )}
              {isSuccess && (
                <div className="mt-2">
                  <TextBubble color="green" text="Project selected!" />
                </div>
              )}
            </>
          ) : (
            <Button href={GOOGLE_API_PERMISSIONS_REGISTER} variant="primary">
              Grant Permissions
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
