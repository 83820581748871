import { Link } from 'react-router-dom';
import { z } from 'zod';

import { GA4_BIGQUERY_LINK_LIST } from 'routes/constants';
/**
 * DEFINTIION: Schema Form
 * For reasons unknown to me we have to define the schema in reverse order.
 */

export const sourceGA4BigQueryPart1SchemaForm = z
  .object({
    // What property IDs are we processing?
    gcloud_project_id: z.string(),
    property_ids: z.array(z.string()).min(1, 'At least one property ID is required'),
    timezone: z.string().optional(),
    run_for_period: z.boolean().optional(),
    run_for_period_start_date: z.string().optional().nullable(),
    run_for_period_end_date: z.string().optional().nullable(),
  })
  .refine(
    (data) => {
      console.log('data', data);
      if (data.run_for_period) {
        return data.run_for_period_start_date !== null;
      }
      return true;
    },
    {
      message: 'Start date is required when run_for_period is true',
      path: ['run_for_period_start_date'],
    }
  )
  .refine(
    (data) => {
      if (data.run_for_period) {
        return data.run_for_period_end_date !== null;
      }
      return true;
    },
    {
      message: 'End date is required when run_for_period is true',
      path: ['run_for_period_end_date'],
    }
  );

export const fieldInfoSourceGA4BigQueryPart1 = {
  id: { name: 'ID' },
  gcloud_project_id: {
    name: 'GCloud Project ID',
    description: 'The GCloud project ID where the raw GA4 data is stored.',
  },
  property_ids: {
    name: 'Property IDs',
    description: (
      <p>
        {
          "Can't find the property ID you're looking for? We only show those which are already exporting to BigQuery. You can find a list of your exporting GA4 properties here - "
        }
        <Link to={GA4_BIGQUERY_LINK_LIST}>View BigQuery Links.</Link>
      </p>
    ),
  },
  timezone: {
    name: 'Timezone',
    description: 'What timezone is your GA4 data in?',
  },
  run_for_period: {
    name: 'Run for period',
    description:
      'Often when first setting up, you want to run for a specific period so you can check the numbers are ok and builds will run more quickly.',
  },
  item_properties_to_unnest: {
    name: 'Item Properties to Unnest',
    description: 'Which extra pieces of information do we want from our items?',
  },
};
