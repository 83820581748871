import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import FormSelect from '../FormSelect/FormSelect';

const timezoneOptions = [
  { value: '-12:00', label: 'GMT-12:00 (International Date Line West)' },
  { value: '-11:00', label: 'GMT-11:00 (Midway Island, Samoa)' },
  { value: '-10:00', label: 'GMT-10:00 (Hawaii)' },
  { value: '-09:00', label: 'GMT-09:00 (Alaska)' },
  { value: '-08:00', label: 'GMT-08:00 (Pacific Time - US & Canada)' },
  { value: '-07:00', label: 'GMT-07:00 (Mountain Time - US & Canada)' },
  { value: '-06:00', label: 'GMT-06:00 (Central Time - US & Canada, Mexico City)' },
  { value: '-05:00', label: 'GMT-05:00 (Eastern Time - US & Canada)' },
  { value: '-04:30', label: 'GMT-04:30 (Caracas)' },
  { value: '-04:00', label: 'GMT-04:00 (Atlantic Time - Canada, Caracas, La Paz)' },
  { value: '-03:30', label: 'GMT-03:30 (Newfoundland)' },
  { value: '-03:00', label: 'GMT-03:00 (Brazil, Buenos Aires, Georgetown)' },
  { value: '-02:00', label: 'GMT-02:00 (Mid-Atlantic)' },
  { value: '-01:00', label: 'GMT-01:00 (Azores, Cape Verde Islands)' },
  { value: '+00:00', label: 'GMT+00:00 (Western Europe Time, London, Lisbon, Casablanca)' },
  { value: '+01:00', label: 'GMT+01:00 (Brussels, Copenhagen, Madrid, Paris)' },
  { value: '+02:00', label: 'GMT+02:00 (Kaliningrad, South Africa)' },
  { value: '+03:00', label: 'GMT+03:00 (Baghdad, Riyadh, Moscow, St. Petersburg)' },
  { value: '+03:30', label: 'GMT+03:30 (Tehran)' },
  { value: '+04:00', label: 'GMT+04:00 (Abu Dhabi, Muscat, Baku, Tbilisi)' },
  { value: '+04:30', label: 'GMT+04:30 (Kabul)' },
  { value: '+05:00', label: 'GMT+05:00 (Ekaterinburg, Islamabad, Karachi, Tashkent)' },
  { value: '+05:30', label: 'GMT+05:30 (Bombay, Calcutta, Madras, New Delhi)' },
  { value: '+05:45', label: 'GMT+05:45 (Kathmandu)' },
  { value: '+06:00', label: 'GMT+06:00 (Almaty, Dhaka)' },
  { value: '+06:30', label: 'GMT+06:30 (Yangon, Cocos Islands)' },
  { value: '+07:00', label: 'GMT+07:00 (Bangkok, Hanoi, Jakarta)' },
  { value: '+08:00', label: 'GMT+08:00 (Beijing, Perth, Singapore, Hong Kong)' },
  { value: '+09:00', label: 'GMT+09:00 (Tokyo, Seoul, Osaka, Sapporo, Yakutsk)' },
  { value: '+09:30', label: 'GMT+09:30 (Adelaide, Darwin)' },
  { value: '+10:00', label: 'GMT+10:00 (Eastern Australia, Guam, Vladivostok)' },
  { value: '+11:00', label: 'GMT+11:00 (Magadan, Solomon Islands, New Caledonia)' },
  { value: '+12:00', label: 'GMT+12:00 (Auckland, Wellington, Fiji, Kamchatka)' },
  { value: '+13:00', label: "GMT+13:00 (Nuku'alofa)" },
  { value: '+14:00', label: 'GMT+14:00 (Line Islands)' },
];

const FormTimezoneSelector = (
  { fieldKey, label, control, errors, description, disabled = false, className },
  ref
) => {
  return (
    <FormSelect
      ref={ref}
      fieldKey={fieldKey}
      label={label}
      control={control}
      errors={errors}
      options={timezoneOptions}
      description={description}
      disabled={disabled}
      className={className}
    />
  );
};

export default forwardRef(FormTimezoneSelector);

FormTimezoneSelector.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
