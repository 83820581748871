import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  useGetBigQueryDatasetsQuery,
  useGetGoogleBigQueryProjectsQuery,
  useLazyGetGA4PropertyByIdQuery,
} from 'services/rtkApi';

export const useGA4BigQueryFormData2 = (queryParams) => {
  const googleAPIAuthStatus = useSelector((state) => state.auth.googleAPIAuthStatus);
  const isAuthorized = googleAPIAuthStatus;

  const [foreignKeyOptions, setForeignKeyOptions] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);
  const [getGA4Property] = useLazyGetGA4PropertyByIdQuery();

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    error: projectsError,
  } = useGetGoogleBigQueryProjectsQuery(undefined, {
    skip: !isAuthorized,
  });

  const {
    data: datasetsData,
    isLoading: isLoadingDatasets,
    error: datasetsError,
    refetch: refetchDatasets,
  } = useGetBigQueryDatasetsQuery(
    { ...queryParams, ignore_ga4_raw_exports: false },
    {
      skip: !isAuthorized || !queryParams.project_id,
    }
  );

  const [isManuallyRefetching, setIsManuallyRefetching] = useState(false);

  // Add logging to track loading state changes
  useEffect(() => {
    console.log('isLoadingDatasets changed:', isLoadingDatasets);
  }, [isLoadingDatasets]);

  // Modify the refetch function to track loading state
  const safeRefetchDatasets = useCallback(() => {
    if (queryParams.project_id) {
      console.log('Refetching datasets...');
      setIsManuallyRefetching(true);
      refetchDatasets()
        // eslint-disable-next-line promise/always-return
        .then(() => {
          console.log('Refetch completed');
          setIsManuallyRefetching(false);
        })
        .catch((err) => {
          console.error('Refetch failed:', err);
          setIsManuallyRefetching(false);
        });
    }
  }, [queryParams.project_id, refetchDatasets]);

  // Effect to trigger dataset refetch when project_id changes
  useEffect(() => {
    safeRefetchDatasets();
  }, [queryParams.project_id, safeRefetchDatasets]);

  // Add this new effect to handle property fetching
  useEffect(() => {
    const fetchProperties = async () => {
      console.log('datasetsData', datasetsData);
      if (!datasetsData) return;

      const propertyIds = datasetsData
        .filter((dataset) => /analytics_\d{7,12}$/.test(dataset.name))
        .map((dataset) => dataset.name.match(/\d{7,12}/)[0]);

      // Reset property options if no IDs found
      if (propertyIds.length === 0) {
        setPropertyOptions([]);
        return;
      }

      setIsLoadingProperties(true);
      try {
        const propertyPromises = propertyIds.map(async (id) => {
          try {
            const result = await getGA4Property({
              propertyId: id,
              projectId: queryParams.project_id,
            }).unwrap();
            return {
              value: result.property_id,
              label: result.display_name,
            };
          } catch (err) {
            return {
              value: id,
              label: `Unknown - ${id}`,
            };
          }
        });
        const propertyResults = await Promise.all(propertyPromises);
        setPropertyOptions(propertyResults);
      } finally {
        setIsLoadingProperties(false);
      }
    };

    fetchProperties();
  }, [datasetsData, queryParams.project_id, getGA4Property]);

  /**
   * Set foreign key options if our data ever changes.
   */
  useEffect(() => {
    const newOptions = [
      {
        key: ['gcloud_project_id'],
        options: projectsData?.map((project) => ({ value: project.id, label: project.name })) || [],
      },
      {
        key: ['bq_output_dataset'],
        options:
          datasetsData?.map((dataset) => ({
            value: dataset.id,
            label: dataset.name,
            location: dataset.location,
          })) || [],
      },
      {
        key: ['property_ids'],
        options: propertyOptions,
      },
    ];

    if (!isEqual(newOptions, foreignKeyOptions)) {
      setForeignKeyOptions(newOptions);
    }
  }, [projectsData, datasetsData, propertyOptions, foreignKeyOptions]);

  /**
   * Set loading foreign keys if any of our queries are loading.
   */
  const loadingForeignKeys = [
    ...(isLoadingDatasets || isManuallyRefetching ? [['bq_output_dataset']] : []),
    ...(isLoadingProjects ? [['gcloud_project_id']] : []),
    ...(isLoadingDatasets || isLoadingProperties || isManuallyRefetching ? [['property_ids']] : []),
    ...(isLoadingDatasets || isLoadingProperties ? [['property_ids']] : []),
  ];

  console.log('isLoadingDatasets', isLoadingDatasets);
  console.log('isLoadingProperties', isLoadingProperties);
  console.log('loadingForeignKeys', loadingForeignKeys);

  return {
    foreignKeyOptions,
    loadingForeignKeys,
    refetchDatasets: safeRefetchDatasets,
    isAuthorized,
    errors: {
      projects: projectsError,
      datasets: datasetsError,
    },
    datasets: datasetsData || [],
  };
};
