/* eslint-disable react/prop-types */
import { TrashIcon } from '@heroicons/react/24/outline';
import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { z } from 'zod';

import Button from 'components/Button/Button';

import CustomLayoutConversionSegment from './FormLogicBuilder/CustomLayoutConversionSelector';
import {
  findForeignKeyOptions,
  getBorderColor,
  getDefaultValue,
  getFieldInfo,
  getNestedError,
  getSchemaFromZod,
  getZodInnerType,
} from './formUtils';
import { renderSchemaFlatField } from './renderSchemaFlatField';

/**
 * Main function to render form fields based on schema
 * @param {Object} props - Rendering props
 */
const renderSchema = ({
  schema,
  name,
  control,
  register,
  errors,
  depth = 0,
  path = [],
  fieldInfo,
  foreignKeyOptions,
  loadingForeignKeys,
  setValue,
  autoSetFields,
}) => {
  const fieldName = name.split('.').pop();

  /**
   * Start off by getting the field info for the current field so we can
   * generate labels, descriptions, etc.
   */
  // const currentFieldInfo = getFieldInfo(fieldInfo, path);
  // const label = currentFieldInfo.name || fieldName;
  // const description = currentFieldInfo.description || '';
  // const showLabel = currentFieldInfo.showLabel !== false;

  /**
   * Go and get the nested error for this field if we're about generate
   * a specific individual field.
   */
  const fieldError = getNestedError(errors, path);

  /**
   * When we're working with zod schema, we need to get the actual definition
   * we'll use for the form. E.g. object/array/string field etc.
   *
   * However there is often chained methods above it which we need to skip
   * past to get to the actual definition. This fucntion handles that.
   */
  const baseSchema = getSchemaFromZod(schema);
  // console.log('schema', schema);
  // console.log('name', name);

  // console.log('path', path);
  // console.log('baseSchema', baseSchema);

  /**
   * Is the field hidden?
   */
  const isHidden = baseSchema.description && baseSchema.description.hidden;

  /**
   * For some array fields we're just going to use a multi select rather
   * than the array layout. Essentially if it's not an object we're going
   * to use a multi select.
   */
  const isForeignKey = findForeignKeyOptions(foreignKeyOptions, path) !== null;
  const innerType = getZodInnerType(baseSchema);
  const isMulti =
    baseSchema instanceof z.ZodArray &&
    (innerType instanceof z.ZodString ||
      innerType instanceof z.ZodNumber ||
      innerType instanceof z.ZodBoolean);

  let content;
  let isComplexField = false;

  /**
   * For complex fields lets get the field info lable and description
   */
  const currentFieldInfo = getFieldInfo(fieldInfo, path);
  const label = currentFieldInfo.name || name;
  const description = currentFieldInfo.description || '';
  const showLabel = currentFieldInfo.showLabel ?? true;
  const showDescription = currentFieldInfo.showDescription ?? true;
  const showMasterLabel = currentFieldInfo.showMasterLabel ?? true;
  const showMasterDescription = currentFieldInfo.showMasterDescription ?? true;
  const pathLength = path.length;

  if (isForeignKey || isMulti) {
    content = renderSchemaFlatField({
      schema,
      name,
      control,
      register,
      errors,
      path,
      fieldInfo,
      foreignKeyOptions,
      loadingForeignKeys,
      autoSetFields,
    });
  } else if (baseSchema instanceof z.ZodArray) {
    content = (
      <ArrayFieldComponent
        schema={baseSchema}
        name={name}
        control={control}
        register={register}
        errors={errors}
        depth={depth}
        path={path}
        fieldInfo={fieldInfo}
        foreignKeyOptions={foreignKeyOptions}
        loadingForeignKeys={loadingForeignKeys}
        setValue={setValue}
        autoSetFields={autoSetFields}
      />
    );
    isComplexField = true;
  } else if (baseSchema instanceof z.ZodObject) {
    content = renderObjectField({
      schema: baseSchema,
      name,
      control,
      register,
      errors,
      depth,
      path,
      fieldInfo,
      foreignKeyOptions,
      loadingForeignKeys,
      setValue,
      autoSetFields,
    });
    isComplexField = true;
    // eslint-disable-next-line sonarjs/no-duplicated-branches
  } else {
    content = renderSchemaFlatField({
      schema,
      name,
      control,
      register,
      errors,
      path,
      fieldInfo,
      foreignKeyOptions,
      loadingForeignKeys,
      autoSetFields,
    });
  }

  const commonClasses = `form-field ${isHidden ? 'hidden' : ''} ${
    depth === 0 ? 'sm:col-span-6' : ''
  } ${isComplexField ? 'w-full' : ''}`; // Adjust width based on field complexity

  return (
    <div className={commonClasses}>
      {isComplexField && (
        <>
          {showMasterLabel && label && pathLength === 1 && (
            <h3 className="font-medium text-gray-900 mb-2 mt-2">{label}</h3>
          )}
          {showMasterDescription && description && pathLength === 1 && (
            <p className="text-sm text-gray-500 mb-2">{description}</p>
          )}
        </>
      )}
      {isComplexField ? (
        <div className="bg-gray-50 shadow-sm mb-4 p-1 transition-colors duration-200">
          {content}
        </div>
      ) : (
        content
      )}
    </div>
  );
};

/**
 * Renders an object field.
 * @param {Object} props - Object field rendering props
 */
const renderObjectField = ({
  schema,
  name,
  control,
  register,
  errors,
  depth,
  path,
  fieldInfo,
  foreignKeyOptions,
  loadingForeignKeys,
  setValue,
}) => {
  /**
   * OBJECTS
   * These are the most complex and most likely to have custom layouts.
   *
   * Those are defined below.
   */
  const schemaShape = schema.shape;
  const fields = Object.entries(schemaShape);

  /**
   * Define the label and description for the field
   */
  const currentFieldInfo = getFieldInfo(fieldInfo, path);
  const label = currentFieldInfo.name || name;
  const description = currentFieldInfo.description || '';
  const showLabel = currentFieldInfo.showLabel ?? true;
  const showDescription = currentFieldInfo.showDescription ?? true;
  const showObjectLabel = currentFieldInfo.showObjectLabel ?? true;
  const showObjectDescription = currentFieldInfo.showObjectDescription ?? true;

  /**
   * Count the number of visible fields. We have a custom layout for 3 columns.
   */
  const visibleFields = fields.filter(([_, fieldSchema]) => {
    const processedSchema = getSchemaFromZod(fieldSchema);
    const innerType = getZodInnerType(processedSchema);
    return !(innerType.description && innerType.description.hidden);
  });

  const simpleFields = visibleFields.filter(([_, fieldSchema]) => {
    const processedSchema = getSchemaFromZod(fieldSchema);
    return !(processedSchema instanceof z.ZodObject) && !(processedSchema instanceof z.ZodArray);
  });

  const isSimpleObject = simpleFields.length <= 4 && simpleFields.length === visibleFields.length;

  /**
   * Catch any specific layouts for this form. First up conversion value layout
   */
  const isConversionValueLayout =
    'col' in schemaShape && 'agg' in schemaShape && 'on_condition_true' in schemaShape;

  return (
    <div className="">
      {showLabel && showObjectLabel && label && (
        <h3 className="text-sm font-medium text-gray-900 mb-2 mt-2">{label}</h3>
      )}
      {showDescription && showObjectDescription && description && (
        <p className="text-sm text-gray-500 mb-2">{description}</p>
      )}
      {isConversionValueLayout ? (
        <CustomLayoutConversionSegment
          fields={schemaShape}
          name={name}
          control={control}
          register={register}
          errors={errors}
          depth={depth}
          path={path}
          fieldInfo={fieldInfo}
          foreignKeyOptions={foreignKeyOptions}
          loadingForeignKeys={loadingForeignKeys}
          setValue={setValue}
        />
      ) : (
        <div className={`${isSimpleObject ? 'grid grid-cols-3 gap-4' : ''}`}>
          {fields.map(([fieldName, fieldSchema]) => (
            <React.Fragment key={fieldName}>
              {renderSchema({
                schema: fieldSchema,
                name: `${name}.${fieldName}`,
                control,
                register,
                errors,
                depth: depth + 1,
                path: [...path, fieldName],
                fieldInfo,
                foreignKeyOptions,
                loadingForeignKeys,
                setValue,
              })}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

/**
 * Renders an array field as a React component.
 * @param {Object} props - Array field rendering props
 */
// eslint-disable react/prop-types
const ArrayFieldComponent = ({
  schema,
  name,
  control,
  register,
  errors,
  depth,
  path,
  fieldInfo,
  foreignKeyOptions,
  loadingForeignKeys,
  setValue,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const handleAddItem = useCallback(() => {
    const defaultValue = getDefaultValue(schema.element);
    append(defaultValue);
  }, [schema.element, append]);

  // Get min and max values from the schema
  const minItems = schema._def.minLength?.value ?? 0;
  const maxItems = schema._def.maxLength?.value ?? Infinity;

  /**
   * Define the label and description for the field
   */
  const currentFieldInfo = getFieldInfo(fieldInfo, path);
  const label = currentFieldInfo.name || name;
  const description = currentFieldInfo.description || '';
  const showLabel = currentFieldInfo.showLabel ?? true;
  const showDescription = currentFieldInfo.showDescription ?? true;
  const showArrayLabel = currentFieldInfo.showArrayLabel ?? true;
  const showArrayDescription = currentFieldInfo.showArrayDescription ?? true;

  return (
    <div className="mt-4">
      {showLabel && showArrayLabel && label && (
        <h3 className="text-sm font-medium text-gray-900 mb-2">{label}</h3>
      )}
      {showDescription && showArrayDescription && description && (
        <p className="text-sm text-gray-500 mb-2">{description}</p>
      )}
      {fields.map((field, index) => (
        <div
          key={field.id}
          className={`logic-item pl-4 mb-4 last:mb-0 m-4 relative
          border-l-4 border-${getBorderColor(depth)}`}
        >
          {fields.length > minItems && (
            <button
              type="button"
              onClick={() => remove(index)}
              className="absolute top-2 right-2 text-gray-500 transition-colors duration-200"
            >
              <TrashIcon className="h-5 w-5" />
            </button>
          )}
          {renderSchema({
            schema: schema.element,
            name: `${name}.${index}`,
            control,
            register,
            errors,
            depth: depth + 1,
            path: [...path, index],
            fieldInfo,
            foreignKeyOptions,
            loadingForeignKeys,
            setValue,
          })}
        </div>
      ))}
      {fields.length < maxItems && (
        <Button onClick={handleAddItem} variant="primary" className="mt-4 ml-4 mb-4">
          Add Item
        </Button>
      )}
    </div>
  );
};
// eslint-enable react/prop-types
export default renderSchema;
