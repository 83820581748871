import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from 'components/Button/Button';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { activateAccount } from 'services/auth/auth';

const LoginActivated = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activationStatus, setActivationStatus] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const uid = params.get('uid');
    const token = params.get('token');

    if (uid && token) {
      activateAccount(uid, token)
        .then(() => {
          setIsSuccess(true);
        })
        .catch(() => {
          setActivationStatus('Activation has been unsuccessful.');
          throw new Error('Activation failed');
        });
    } else {
      setActivationStatus('Invalid activation link.');
    }
  }, [location.search]);

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {isSuccess ? 'Account Activated!' : 'Activating your account'}
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6">
        {activationStatus ? (
          <div className="text-center text-red-600">{activationStatus}</div>
        ) : isSuccess ? (
          <div className="text-center">
            <p className="mb-4">Thanks for activating your account, now please login.</p>
            <Button href="/login" variant="primary">
              Go to Login
            </Button>
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};

export default LoginActivated;
