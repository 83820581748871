import GenericJsonForm from 'components/Forms/GenericJsonForm/GenericJsonForm';
import {
  useAddSourceGA4BigQueryMutation,
  useGetDbtProjectFileQuery,
  useUpdateDbtProjectFileMutation,
} from 'services/rtkApi';

/**
 * SourceGA4BigQueryFormRaw component for raw JSON editing of GA4 BigQuery source
 * @param {Object} props - Component props
 * @param {string} props.entityId - ID of the entity to edit
 * @param {Function} props.onSuccess - Callback function on successful update
 * @param {string} props.propertyId - ID of the property
 */
function SourceGA4BigQueryFormRaw({ entityId, onSuccess, propertyId }) {
  const isUpdate = Boolean(entityId);

  const queryHook = isUpdate ? useGetDbtProjectFileQuery : undefined;
  const mutationHook = isUpdate ? useUpdateDbtProjectFileMutation : useAddSourceGA4BigQueryMutation;

  // TODO RAW JSON Should update entire data, not just single field. otheriwse you can't fix
  // shit with it.

  const { data, isLoading, error } = queryHook
    ? queryHook(entityId)
    : { data: { property_id: propertyId } };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <GenericJsonForm
      id={entityId}
      data={data}
      updateMutation={mutationHook}
      onSuccess={onSuccess}
    />
  );
}

export default SourceGA4BigQueryFormRaw;
