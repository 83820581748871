import { useParams } from 'react-router-dom';

import PipelineRunView from 'components/_displayModel/pipelineRuns/PipelineRunModelDisplay';
import Title from 'components/Title/Title';
/**
 * Page component for viewing a pipeline run
 */
function PipelineRunViewPage() {
  const { id } = useParams();

  return (
    <div className="px-4 sm:px-6 lg:px-8 mb-6 mt-6">
      <Title>Pipeline Run Details</Title>
      <PipelineRunView id={id} />
    </div>
  );
}

export default PipelineRunViewPage;
