import { z } from 'zod';

export const ga4BigQueryLinkSchemaForm = z.object({
  name: z.string().optional(),
  project: z.string(),
  dataset_location: z.string(),
  daily_export_enabled: z.boolean(),
  excluded_events: z.array(z.string()).optional(),
  export_streams: z.array(z.string()).optional(),
  fresh_daily_export_enabled: z.boolean(),
  include_advertising_id: z.boolean().optional(),
  streaming_export_enabled: z.boolean(),
});

// Add a separate object for auto-set fields
export const autoSetFields = {
  name: true,
};

export const ga4BigQueryLinkSchemaList = z.object({
  name: z.string(),
  project: z.string(),
  dataset_location: z.string(),
  daily_export_enabled: z.boolean(),
  excluded_events: z.array(z.string()).optional(),
  export_streams: z.array(z.string()).optional(),
  fresh_daily_export_enabled: z.boolean(),
  include_advertising_id: z.boolean().optional(),
  streaming_export_enabled: z.boolean(),
  create_time: z.string().optional(),
});

export const fieldInfoGA4BigQueryLink = {
  name: { name: 'Name', description: 'The resource name of the GA4 BigQuery link' },
  project: {
    name: 'Project',
    description: 'The Google Cloud project ID where the BigQuery dataset is',
  },
  dataset_location: {
    name: 'Dataset Location',
    description: 'The location of the BigQuery dataset',
  },
  daily_export_enabled: {
    name: 'Daily Export Enabled',
    description: 'Whether daily export is enabled for this link',
  },
  excluded_events: {
    name: 'Excluded Events',
    description: 'A list of events to exclude from the export',
  },
  export_streams: {
    name: 'Export Streams',
    description: 'A list of export streams for this link',
  },
  fresh_daily_export_enabled: {
    name: 'Fresh Daily Export Enabled',
    description: 'Whether fresh daily export is enabled for this link',
  },
  include_advertising_id: {
    name: 'Include Advertising ID',
    description: 'Whether to include advertising ID in the export',
  },
  streaming_export_enabled: {
    name: 'Streaming Export Enabled',
    description: 'Whether streaming export is enabled for this link',
  },
  create_time: {
    name: 'Create Time',
    description: 'The time when this link was created',
  },
};
