import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import SQLEditorForm from 'components/SQLEditor/SQLEditorForm';
import {
  useGetDbtProjectFileQuery,
  useGetPipelineByIdQuery,
  useGetPipelineGithubFileQuery,
  useUpdateSourceGA4BigQueryMutation,
  useValidateGA4CustomChannelGroupingMutation,
} from 'services/rtkApi';

/**
 * SourceGA4BigQueryChannelGroupings component for editing GA4 BigQuery channel groupings
 * @param {Object} props - Component props
 * @param {string} props.id - Unique identifier for the form
 * @param {string} props.pipelineId - ID of the associated pipeline
 * @param {string} props.projectId - Google Cloud project ID
 * @param {string} props.dataset - BigQuery dataset name
 */
function SourceGA4BigQueryFormChannelGroupingSQL({ id, pipelineId }) {
  const [sql, setSQL] = useState('');
  const dispatch = useDispatch();

  const {
    data: pipelineData,
    isLoading: isPipelineLoading,
    error: pipelineError,
  } = useGetPipelineByIdQuery(pipelineId);

  const { data: dbtProjectFile, isLoading: isDbtProjectLoading } = useGetDbtProjectFileQuery(id);

  const { data: extraChannelGroupingContent, isLoading: isExtraChannelGroupingLoading } =
    useGetPipelineGithubFileQuery({
      id: pipelineId,
      file_path: 'macros/extra_channel_grouping_1.sql',
      macro_name: 'default__extra_channel_grouping_1',
    });

  const [updateSourceGA4BigQuery] = useUpdateSourceGA4BigQueryMutation();

  useEffect(() => {
    if (!isDbtProjectLoading && !isExtraChannelGroupingLoading) {
      const content = extraChannelGroupingContent?.content || '';
      setSQL(content);
    }
  }, [
    dbtProjectFile,
    extraChannelGroupingContent,
    isDbtProjectLoading,
    isExtraChannelGroupingLoading,
  ]);

  const highlightWords = {
    columns: ['dim__source', 'dim__medium', 'dim__source_category', 'dim__campaign'],
  };

  const handleSubmit = async (sqlText) => {
    try {
      await updateSourceGA4BigQuery({ id, custom_channel_grouping: sqlText }).unwrap();
      // Handle successful update if needed
    } catch (error) {
      console.error('Error updating channel groupings:', error);
      // Dispatch API error
      if (error.response) {
        dispatch({
          type: 'API_ERROR',
          payload: {
            status: error.response.status,
            data: error.response.data,
          },
        });
      }
    }
  };

  if (isDbtProjectLoading || isExtraChannelGroupingLoading) {
    return <LoadingSpinner text="Loading channel groupings" size="16" />;
  }

  console.log('highlightWords', highlightWords);
  return (
    <div className="source-ga4-bigquery-channel-groupings">
      <SQLEditorForm
        title="GA4 BigQuery Channel Groupings"
        initialSQL={sql}
        highlightWords={highlightWords}
        validationMutation={useValidateGA4CustomChannelGroupingMutation}
        validationParams={{
          project_id: pipelineData.gcloud_project_id,
          dataset: pipelineData.bq_output_dataset,
        }}
        onSubmit={handleSubmit}
        mutationHook={useUpdateSourceGA4BigQueryMutation}
        entityId={id}
      />
    </div>
  );
}

export default SourceGA4BigQueryFormChannelGroupingSQL;
