import React from 'react';

import EmptyState from 'components/EmptyState/EmptyState';
import FlexListTable from 'components/FlexListTable/FlexListTable';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import TextBubble from 'components/TextBubble/TextBubble';
import { PIPELINE_RUN_VIEW } from 'routes/constants';
import {
  useGetCurrentUserQuery,
  useGetPipelineRunsQuery,
  useSetPipelineRunStatusMutation,
} from 'services/rtkApi';

const PipelineRunTable = ({ pipelineId }) => {
  const { data, isLoading, isError } = useGetPipelineRunsQuery({
    pipelineId,
    page: 1,
    pageSize: 20,
  });

  // Get current user for admin features.
  const { data: user } = useGetCurrentUserQuery();

  const [setPipelineRunStatus, { isLoading: isStatusLoading }] = useSetPipelineRunStatusMutation();

  console.log(isStatusLoading);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Run Status',
        accessor: 'did_run_succeed',
        Cell: ({ value }) => {
          let color = 'grey';
          let text = 'In Progress';
          if (value === true) {
            color = 'green';
            text = 'Success';
          } else if (value === false) {
            color = 'red';
            text = 'Failed';
          }
          return <TextBubble color={color} text={text} />;
        },
      },
      {
        Header: 'In Progress',
        accessor: 'is_run_in_progress',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
      {
        Header: 'Started At',
        accessor: 'started_at',
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: 'Last Updated',
        accessor: 'updated_at',
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
    ],
    []
  );

  const linkButtons = [
    {
      label: 'View Run',
      link: PIPELINE_RUN_VIEW,
      show: true, // Always show this button
    },
    {
      label: isStatusLoading ? <LoadingSpinner size={4} /> : 'Set Failed',
      onClick: async (row) => {
        await setPipelineRunStatus({
          id: row.id,
          did_run_succeed: false,
          run_summary: 'Set to failed by admin',
        });
      },
      show: user?.is_superuser && !isLoading, // Hide while user data is loading
      disabled: isStatusLoading,
    },
  ];

  console.log(data);

  if (isLoading) return <LoadingSpinner text="Loading pipeline runs..." />;
  if (isError) return <div>Error loading pipeline runs</div>;

  if (!data || data.results.length === 0) {
    return (
      <EmptyState title="No pipeline runs found" description="This pipeline hasn't been run yet." />
    );
  }

  return (
    <FlexListTable
      columns={columns}
      data={data.results || []}
      linkButtons={linkButtons}
      hiddenColumnIds={['id']}
      initialSort={[{ id: 'started_at', desc: true }]}
    />
  );
};

export default PipelineRunTable;
