import { useParams } from 'react-router-dom';

import SourceGA4BigQueryFormActionButtons from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryFormActionButtons';
import SourceGA4BigQueryFormNavigation from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryFormNavigation';
import SourceGA4BigQueryFormRaw from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryFormRaw';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

function SourceGA4BigQueryFormRawPage() {
  const { id, pipelineId } = useParams();
  const isUpdate = Boolean(id);
  const pageTitle = isUpdate ? 'Edit GA4 Pipeline (Raw JSON)' : 'Create GA4 Pipeline (Raw JSON)';

  const handleSuccess = () => {
    // Handle success (e.g., show a notification, redirect)
    console.log('Form submitted successfully');
  };

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">{pageTitle}</h1>
          {isUpdate && (
            <SourceGA4BigQueryFormActionButtons pipelineId={pipelineId} sourceGA4BigQueryID={id} />
          )}
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="flex">
            <SourceGA4BigQueryFormNavigation isUpdate={isUpdate} activeTab="raw" />
            <div className="flex-grow mt-5">
              <div className="text-gray-600 text-sm">
                <p>Directly edit the GA4 pipeline configuration as raw JSON.</p>
              </div>
              <SourceGA4BigQueryFormRaw entityId={id} onSuccess={handleSuccess} />
            </div>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
}

export default SourceGA4BigQueryFormRawPage;
