// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: '',
    name: '',
    gcloud_project_id: null,
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
    },
    clearUserInfo: (state) => {
      state.email = '';
      state.name = '';
      state.gcloud_project_id = null;
    },
    setGCloudProjectId: (state, action) => {
      state.gcloud_project_id = action.payload;
    },
  },
});

// Selector to get gcloud_project_id
export const selectGCloudProjectId = (state) => state.user.gcloud_project_id;

export const { setUserInfo, clearUserInfo, setGCloudProjectId } = userSlice.actions;
export default userSlice.reducer;
