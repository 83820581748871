import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'; // Import Link from React Router

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'; // Adjust the import path as needed

const Button = ({
  loading,
  disabled,
  type,
  children,
  className,
  onClick,
  variant,
  href,
  statusColour,
  isOutline,
  openInNewTab,
  status,
}) => {
  const primaryClass = isOutline
    ? 'bg-transparent border border-deep-sea-green-900 text-deep-sea-green-900 hover:bg-deep-sea-green-800 hover:text-white'
    : 'bg-deep-sea-green-900 hover:bg-deep-sea-green-800 text-white';
  const secondaryClass = isOutline
    ? 'bg-transparent border border-red-damask-500 text-red-damask-500 hover:bg-red-damask-500 hover:text-white'
    : 'bg-red-damask-500 hover:bg-red-damask-600 text-white';
  const successClass = isOutline
    ? 'bg-transparent border border-green-700 text-green-700 hover:bg-green-700 hover:text-white'
    : 'bg-green-700 hover:bg-green-700 text-white';
  const failClass = isOutline
    ? 'bg-transparent border border-red-700 text-red-700 hover:bg-red-700 hover:text-white'
    : 'bg-red-700 hover:bg-red-700 text-white';
  const disabledClass = 'bg-gray-300 text-gray-400 cursor-not-allowed pointer-events-none';

  const isDisabled = loading || disabled;
  const baseClass =
    'rounded px-3 h-9 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600';
  const variantClass = isDisabled
    ? ''
    : variant === 'primary'
    ? primaryClass
    : variant === 'secondary'
    ? secondaryClass
    : variant === 'danger'
    ? failClass
    : secondaryClass;
  const statusClass = statusColour ? (statusColour === 'success' ? successClass : failClass) : '';
  const combinedClass = `${baseClass} ${statusClass || variantClass} ${
    isDisabled ? disabledClass : ''
  } ${className}`;

  const getButtonContent = () => {
    if (loading) return <LoadingSpinner />;
    if (children) return children;
    if (status === 'updated') return <span>✓ Updated</span>;
    if (status === 'failed') return <span>✗ Failed</span>;
    return null;
  };

  const handleClick = (e) => {
    if (openInNewTab && href) {
      e.preventDefault();
      window.open(href, '_blank', 'noopener,noreferrer');
    }
    onClick(e);
  };

  if (href) {
    return (
      <Link
        to={href}
        className={combinedClass}
        onClick={handleClick}
        {...(openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      >
        {getButtonContent()}
      </Link>
    );
  }

  return (
    <button type={type} className={combinedClass} disabled={isDisabled} onClick={handleClick}>
      <div className="flex items-center justify-center h-full">{getButtonContent()}</div>
    </button>
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'danger']),
  statusColour: PropTypes.oneOf(['success', 'fail', '', null]),
  isOutline: PropTypes.bool,
  openInNewTab: PropTypes.bool,
};

Button.defaultProps = {
  loading: false,
  disabled: false,
  type: 'button',
  onClick: () => {},
  variant: 'primary',
  statusColour: '',
  isOutline: false,
  openInNewTab: false,
};

export default Button;
