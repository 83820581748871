import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-toastify';

import FormSelectNonHookForm from 'components/FormNonReactHook/FormSelectNonHookForm';

/**
 * AvailableValuesForm component for displaying and copying available values using a select input
 * @param {Object} props - Component props
 * @param {Array<string|Object>} props.values - List of available values or objects with value and label
 * @param {string} [props.valueType='columns'] - Type of values being displayed
 */
function AvailableValuesForm({ valuesObject, valueType = 'columns' }) {
  const [selectedValue, setSelectedValue] = useState(null);

  const options = valuesObject.map((item) => {
    if (typeof item === 'object' && item.value && item.label) {
      return { value: item.value, label: item.label };
    }
    return { value: item, label: item };
  });

  const handleChange = (value) => {
    if (value) {
      navigator.clipboard.writeText(value);
      toast.success(`Copied ${value} to clipboard!`, {
        position: 'bottom-right',
        autoClose: 2000,
      });
      setSelectedValue(value);
    }
  };

  console.log(valuesObject);
  console.log(valueType);

  return (
    <div className="mb-4 max-w-xl">
      <FormSelectNonHookForm
        fieldKey="availableValues"
        label={`Available ${valueType}`}
        value={selectedValue}
        onChange={handleChange}
        options={options}
      />
    </div>
  );
}

AvailableValuesForm.propTypes = {
  valuesObject: PropTypes.arrayOf(PropTypes.string).isRequired,
  valueType: PropTypes.string,
};

export default AvailableValuesForm;
