import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '../Tooltip/Tooltip'; // Import Tooltip component

/**
 * Displays model data in a field name: value format with Tailwind CSS styling
 * @param {Object} props
 * @param {Object} props.data - The model data from the API
 * @param {Object} props.fieldNames - Lookup for nice field names
 * @param {Array} props.formatters - List of [fieldId, formatterFunction] pairs
 * @param {Array} props.bottomFields - List of field IDs to display below the columns
 */
const ModelDisplay = ({ data, fieldNames, formatters = [], bottomFields = [] }) => {
  const formatValue = (fieldId, value) => {
    const formatter = formatters.find(([id]) => id === fieldId)?.[1];
    return formatter ? formatter(value) : value || 'N/A'; // Default to 'N/A' if value is empty
  };

  // Split entries into main and bottom sections
  const entries = Object.entries(data);
  const mainEntries = entries.filter(([fieldId]) => !bottomFields.includes(fieldId));
  const bottomEntries = entries.filter(([fieldId]) => bottomFields.includes(fieldId));

  const midpoint = Math.ceil(mainEntries.length / 2);

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-4 truncate">
        {[mainEntries.slice(0, midpoint), mainEntries.slice(midpoint)].map((column, colIndex) => (
          <React.Fragment key={colIndex}>
            <dl className="space-y-4">
              {column.map(([fieldId]) => (
                <dt key={fieldId} className="text-sm font-medium text-gray-500 min-h-[1.5rem]">
                  {fieldNames[fieldId]?.name || fieldId}
                </dt>
              ))}
            </dl>
            <dl className="space-y-4">
              {column.map(([fieldId, value]) => (
                <dd key={fieldId} className="text-sm text-gray-900 min-h-[1.5rem] ">
                  <Tooltip content={formatValue(fieldId, value)} position="left">
                    <span className="truncate block max-w-xs">{formatValue(fieldId, value)}</span>
                  </Tooltip>
                </dd>
              ))}
            </dl>
          </React.Fragment>
        ))}
      </div>

      {bottomEntries.length > 0 && (
        <div className="space-y-4">
          {bottomEntries.map(([fieldId, value]) => (
            <div key={fieldId} className="space-y-1">
              <dt className="text-sm font-medium text-gray-500">
                {fieldNames[fieldId]?.name || fieldId}
              </dt>
              <dd className="text-sm text-gray-900">{formatValue(fieldId, value)}</dd>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ModelDisplay.propTypes = {
  data: PropTypes.object.isRequired,
  fieldNames: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  formatters: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
  ),
  bottomFields: PropTypes.arrayOf(PropTypes.string),
};

ModelDisplay.defaultProps = {
  formatters: [],
  bottomFields: [],
};

export default ModelDisplay;
