import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

/**
 * ActionModal component for displaying action-specific modals with customizable content and buttons.
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {function} props.onClose - Function to call when closing the modal
 * @param {string} props.title - Modal title
 * @param {string|node} props.description - Modal description
 * @param {string} props.actionType - Type of action (e.g., 'delete')
 * @param {Array} props.buttons - Array of button configurations
 */
const ModalAction = ({ isOpen, onClose, title, description, actionType, buttons }) => {
  const getIcon = () => {
    switch (actionType) {
      case 'delete':
        return <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />;
      // Add more cases for different action types
      default:
        return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            {getIcon()}
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
            <div className="mt-2">
              <div className="text-sm text-gray-500">{description}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        {buttons.map((button, index) => (
          <Button
            key={index}
            onClick={button.onClick}
            variant={button.variant}
            className={`${index > 0 ? 'sm:mr-3' : ''} ${
              index === 0 ? 'sm:ml-3' : ''
            } mt-3 sm:mt-0 sm:w-auto`}
          >
            {button.text}
          </Button>
        ))}
      </div>
    </Modal>
  );
};

ModalAction.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  actionType: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      variant: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ModalAction;
