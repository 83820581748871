import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';

import TextBubble from 'components/TextBubble/TextBubble';

/**
 * AvailableValues component for displaying and copying available values
 * @param {Object} props - Component props
 * @param {Object|null} props.highlightWords - Object where keys are categories and values are arrays of words, or null
 * @param {Function} props.onCopy - Function to handle value copying
 * @param {string|null} props.copiedValue - Currently copied value
 * @param {string} [props.valueType='columns'] - Type of values being displayed
 * @param {number} [props.numColumns=0] - Number of columns to display (0 for flex layout)
 */
function AvailableValues({
  valuesObject,
  onCopy,
  copiedValue,
  valueType = 'columns',
  numColumns = 0,
}) {
  if (!valuesObject) {
    return null;
  }

  const containerClass = numColumns > 0 ? `grid grid-cols-${numColumns} gap-2` : 'flex flex-wrap';
  const categories = Object.keys(valuesObject);
  const showSubheadings = categories.length > 1;

  return (
    <>
      <div className="text-sm text-gray-500 mr-2 mb-2 mt-4">Available {valueType}:</div>
      {categories.map((category) => (
        <div key={category} className="mb-4">
          {showSubheadings && (
            <div className="text-sm font-semibold text-gray-700 mb-2">{category}</div>
          )}
          <div className={containerClass}>
            {valuesObject[category].map((value) => (
              <div key={value} className={numColumns === 0 ? 'mr-2 mb-2' : ''}>
                <TextBubble
                  color="blue"
                  text={value}
                  onClick={() => onCopy(value)}
                  className={`highlighted-word-${category}`}
                >
                  {copiedValue === value && (
                    <DocumentDuplicateIcon className="ml-2 h-4 w-4 text-green-500" />
                  )}
                </TextBubble>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

AvailableValues.propTypes = {
  valuesObject: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  onCopy: PropTypes.func.isRequired,
  copiedValue: PropTypes.string,
  valueType: PropTypes.string,
  numColumns: PropTypes.number,
};

export default AvailableValues;
