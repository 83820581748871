import { useCallback } from 'react';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import {
  fieldInfoSourceGA4DBTColumnsToUnnest,
  sourceGA4BigQueryColumnsToUnnestSchemaForm,
} from 'schemas/sourceGA4DBT/sourceGA4DBTColumnsToUnnestSchema';
import { useGetDbtProjectFileQuery, usePartialUpdateDbtProjectFileMutation } from 'services/rtkApi';

const SourceGA4BigQueryFormStageColumnsToUnnest = ({ entityId, onSuccess, propertyId }) => {
  /**
   * This form should never be used with create.
   */
  const isUpdate = Boolean(entityId);
  const formType = isUpdate ? 'update' : 'create';

  const mutationHook = isUpdate ? usePartialUpdateDbtProjectFileMutation : undefined;
  const queryHook = isUpdate ? useGetDbtProjectFileQuery : undefined;

  const onFieldChange = useCallback((formValues, changedFields) => {
    console.log('formValues', formValues);
    console.log('changedFields', changedFields);
  });

  return (
    <CrudForm
      entityId={entityId}
      key={`pipeline_${formType}`}
      queryHook={queryHook}
      formType={formType}
      mutationHook={mutationHook}
      schema={sourceGA4BigQueryColumnsToUnnestSchemaForm}
      fieldInfo={fieldInfoSourceGA4DBTColumnsToUnnest}
      onSuccess={onSuccess}
      warnOnUnsavedChanges
      initialValues={{ property_id: propertyId }}
      onFieldChange={onFieldChange}
    />
  );
};

export default SourceGA4BigQueryFormStageColumnsToUnnest;
