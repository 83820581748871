import { zodResolver } from '@hookform/resolvers/zod';
import { createContext, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { sourceGA4BigQueryPart1SchemaForm } from 'schemas/sourceGA4DBT/sourceGA4DBTPage1Schema';

const GA4FormStage1Context = createContext(null);

export const GA4FormStage1StateProvider = ({ children }) => {
  const methods = useForm({
    resolver: zodResolver(sourceGA4BigQueryPart1SchemaForm),
    mode: 'onBlur',
  });

  return (
    <GA4FormStage1Context.Provider value={methods}>
      <FormProvider {...methods}>{children}</FormProvider>
    </GA4FormStage1Context.Provider>
  );
};

export const useGA4FormStage1State = () => {
  const context = useContext(GA4FormStage1Context);
  if (!context) {
    throw new Error('useGA4FormStage1State must be used within a GA4FormStage1StateProvider');
  }
  return context;
};
