import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import {
  fieldInfoSourceGA4DBTEcommerce,
  sourceGA4BigQueryEcommerceSchemaForm,
} from 'schemas/sourceGA4DBT/sourceGA4DBTEcommerceSchema';
import {
  useAddSourceGA4BigQueryMutation,
  useGetSourceGA4BigQueryByIdQuery,
  useUpdateSourceGA4BigQueryMutation,
} from 'services/rtkApi';

import { useGA4BigQueryFormData } from '../useGA4BigQueryFormData';

const SourceGA4BigQueryFormStage1 = ({ onSuccess }) => {
  const { id, pipelineId } = useParams();
  const isUpdate = Boolean(id);
  const formType = isUpdate ? 'update' : 'create';

  const { formData, isLoading, error } = useGA4BigQueryFormData(pipelineId);

  // Use initial values only for create form
  const initialValues = React.useMemo(() => {
    return isUpdate ? {} : {};
  }, [isUpdate]);

  // Get existing data for the form.
  const mutationHook = isUpdate
    ? useUpdateSourceGA4BigQueryMutation
    : useAddSourceGA4BigQueryMutation;
  const queryHook = isUpdate ? useGetSourceGA4BigQueryByIdQuery : undefined;

  const onFieldChange = (formValues, changedFields) => {
    console.log('formValues', formValues);
    console.log('changedFields', changedFields);
  };

  // TODO Move back to individual URls will make it far easier to move and automatically
  // redirect you around the form.

  // When you leave each stage we need to check for unsaved changes and warn the user.

  // Updated foreignKeyOptions to match the new format
  const foreignKeyOptions = useMemo(
    () => [
      {
        key: ['property_ids'],
        options: formData?.map((item) => ({ value: item.id, label: item.name })) || [],
      },
    ],
    [formData]
  );

  // Updated loadingForeignKeys to match the new format
  const loadingForeignKeys = useMemo(() => (isLoading ? [['property_ids']] : []), [isLoading]);

  return (
    <CrudForm
      entityId={id}
      key={`pipeline_${formType}`}
      formType={formType}
      mutationHook={mutationHook}
      queryHook={queryHook}
      schema={sourceGA4BigQueryEcommerceSchemaForm}
      foreignKeyOptions={foreignKeyOptions}
      loadingForeignKeys={loadingForeignKeys}
      fieldInfo={fieldInfoSourceGA4DBTEcommerce}
      onSuccess={onSuccess}
      initialValues={{ ...initialValues, pipeline: pipelineId }}
      warnOnUnsavedChanges
      onFieldChange={onFieldChange}
      // formTitle="Ecommerce Settings"
    />
  );
};

export default SourceGA4BigQueryFormStage1;
