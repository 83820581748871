import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import ModelDisplay from 'components/ModelDisplay/ModelDisplay';
import { fieldInfoPipeline } from 'schemas/pipelineSchema';
import { useGetPipelineByIdQuery } from 'services/rtkApi';

/**
 * Displays pipeline data fetched from the API
 * @param {Object} props
 * @param {Function} props.onSuccess - Callback function on successful data fetch
 */
const PipelineModelDisplay = ({ onSuccess }) => {
  const { id } = useParams();
  const { data: pipeline, isLoading, isError } = useGetPipelineByIdQuery(id);

  React.useEffect(() => {
    if (pipeline && !isLoading && !isError) {
      onSuccess?.(pipeline);
    }
  }, [pipeline, isLoading, isError, onSuccess]);

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <p>Error loading pipeline data</p>;

  const formatters = [
    ['created_at', (date) => new Date(date).toLocaleString()],
    ['updated_at', (date) => new Date(date).toLocaleString()],
  ];

  // Filter out unwanted fields from data
  const filteredData = pipeline
    ? Object.fromEntries(
        Object.entries(pipeline).filter(
          ([key]) => !['id', 'account', 'archived', 'archived_at'].includes(key)
        )
      )
    : null;

  return filteredData ? (
    <ModelDisplay data={filteredData} fieldNames={fieldInfoPipeline} formatters={formatters} />
  ) : null;
};

PipelineModelDisplay.propTypes = {
  onSuccess: PropTypes.func,
};

export default PipelineModelDisplay;
