import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import PipelineActions from 'components/_displayModel/pipelines/PipelineActions';
import PipelineModelDisplay from 'components/_displayModel/pipelines/PipelineModelDisplay';
import PipelineRunTable from 'components/_tables/pipelineRuns/PipelineRunTable';
import SourceGA4BigQueryTable from 'components/_tables/source_ga4_bigquery/SourceGA4BigQueryTable';
import ModalAction from 'components/ModalAction/ModalAction';
import PageAccordionStrip from 'components/PageAccordionStrip/PageAccordionStrip';
import * as route from 'routes/constants';
import { useDeletePipelineMutation } from 'services/rtkApi';
import PageLayout from 'templates/PageLayout';
/**
 * PipelineSummaryPage component displaying pipeline information, data sources, and runs.
 */
const PipelineSummaryPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletePipeline] = useDeletePipelineMutation();
  const [isPipelineDeleted, setIsPipelineDeleted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false); // New state for deletion in progress

  const handleSuccess = (pipelineData) => {
    console.log('Pipeline data loaded:', pipelineData);
    // Handle what happens after successful data fetch
  };

  const handleEdit = () => {
    navigate(route.PIPELINE_UPDATE_FORM.replace(':id', id));
  };

  // Horrendous mess to try and prevent a race condition where the model summary
  // is re-requested by the table after the pipeline has been deleted.
  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await deletePipeline(id).unwrap();
      setIsDeleteModalOpen(false);
      setIsPipelineDeleted(true);
      navigate(route.PIPELINE_LIST, { replace: true });
    } catch (error) {
      console.error('Failed to delete pipeline:', error);
      setIsDeleteModalOpen(false);
    } finally {
      setIsDeleting(false); // Reset deleting state
    }
  };

  const pipelineInfoButtons = [
    {
      text: 'Edit Pipeline',
      onClick: handleEdit,
      props: { variant: 'primary', isOutline: true },
    },
    {
      text: 'Delete Pipeline',
      onClick: () => setIsDeleteModalOpen(true),
      props: { variant: 'danger', isOutline: false },
    },
  ];

  return (
    <PageLayout isItGrey>
      <div>
        <PageAccordionStrip title="Pipeline Information" buttons={pipelineInfoButtons}>
          {!isPipelineDeleted && !isDeleting && <PipelineModelDisplay onSuccess={handleSuccess} />}
          {isDeleting && <p>Deleting pipeline...</p>}
          {isPipelineDeleted && <p>Pipeline has been deleted.</p>}
        </PageAccordionStrip>
        <PageAccordionStrip title="Pipeline Actions">
          <PipelineActions className="mt-4" pipelineId={id} />
        </PageAccordionStrip>
        <PageAccordionStrip title="Data Sources" hasTopBorderm className="mb-4">
          <SourceGA4BigQueryTable pipelineId={id} />
        </PageAccordionStrip>
        <PageAccordionStrip title="Pipeline Runs" hasTopBorder className="mb-4">
          <PipelineRunTable pipelineId={id} />
        </PageAccordionStrip>
      </div>
      <ModalAction
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Confirm Deletion"
        description="Are you sure you want to delete this pipeline? This action cannot be undone."
        actionType="delete"
        buttons={[
          {
            text: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            variant: 'primary',
          },
          {
            text: 'Delete',
            onClick: handleDelete,
            variant: 'danger',
          },
        ]}
      />
    </PageLayout>
  );
};

export default PipelineSummaryPage;
