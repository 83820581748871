import { cloneDeep, get, set } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

const handleArrayPath = (path, formValues, callback) => {
  if (!path || !Array.isArray(path)) {
    console.error('Invalid path:', path);
    return;
  }

  const arrayFieldIndex = path.findIndex((part) => part === '*');

  if (arrayFieldIndex === -1) {
    // If there's no '*', just call the callback once with the original path
    callback(path);
    return;
  }

  const arrayField = path.slice(0, arrayFieldIndex);
  const arrayItems = get(formValues, arrayField, []);

  arrayItems.forEach((_, index) => {
    const fullPath = [...path.slice(0, arrayFieldIndex), index, ...path.slice(arrayFieldIndex + 1)];
    callback(fullPath, index);
  });
};

const flattenOptions = (obj, prefix = []) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newPrefix = [...prefix, key];
    if (
      Array.isArray(value) &&
      value.every((item) => typeof item === 'object' && 'label' in item && 'value' in item)
    ) {
      acc.push({ key: newPrefix, options: value });
    } else if (typeof value === 'object' && value !== null) {
      acc.push(...flattenOptions(value, newPrefix));
    }
    return acc;
  }, []);
};

const useDynamicForeignKeyOptions = (initialForeignKeyOptions, dynamicFieldsConfig) => {
  const [dynamicOptions, setDynamicOptions] = useState({});

  const updateDynamicOptions = useCallback(
    (formValues, changedFields) => {
      // console.log('updateDynamicOptions called', { formValues, changedFields });
      let newDynamicOptions = cloneDeep(dynamicOptions);
      let hasChanges = false;

      dynamicFieldsConfig.forEach((config) => {
        handleArrayPath(config.field, formValues, (fullFieldPath) => {
          const fieldValue = get(formValues, fullFieldPath);

          const changedField = changedFields.find(
            (field) => JSON.stringify(field.path) === JSON.stringify(fullFieldPath)
          );

          if (changedField) {
            const matchingOption = config.options.find((option) => option.value === fieldValue);

            if (matchingOption) {
              handleArrayPath(config.dependentField, formValues, (fullDependentFieldPath) => {
                set(newDynamicOptions, fullDependentFieldPath, matchingOption.dependentOptions);
                hasChanges = true;
              });
            }
          }
        });
      });

      if (hasChanges) {
        // console.log('Setting new dynamic options:', newDynamicOptions);
        setDynamicOptions(newDynamicOptions);
      }
    },
    [dynamicOptions, dynamicFieldsConfig]
  );

  const combinedForeignKeyOptions = useMemo(() => {
    const flattenedDynamicOptions = flattenOptions(dynamicOptions);

    const combined = initialForeignKeyOptions.map((option) => ({
      ...option,
      key: Array.isArray(option.key) ? option.key : [option.key],
    }));

    flattenedDynamicOptions.forEach(({ key, options }) => {
      const formattedKey = key.map((part) => (isNaN(part) ? part : '*'));
      combined.push({ key: formattedKey, options });
    });

    return combined;
  }, [initialForeignKeyOptions, dynamicOptions]);

  return {
    combinedForeignKeyOptions,
    updateDynamicOptions,
  };
};

export default useDynamicForeignKeyOptions;
