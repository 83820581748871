import { useDispatch, useSelector } from 'react-redux';

import BigQueryDatasetCreate from 'components/_forms/bigquery/BigQueryDatasetCreate';
import Drawer from 'components/Drawer/Drawer';
import { closeDrawer } from 'services/drawers/drawerSlice';

const contentMap = {
  CREATE_DATASET_FORM: (args) => <BigQueryDatasetCreate {...args} />,
  // Add other content types as needed
};

const GlobalDrawer = () => {
  const refreshToken = useSelector((state) => state.auth?.refreshToken);
  const drawer = useSelector((state) => state.drawer || {});
  const dispatch = useDispatch();

  // Destructure after hooks, with defaults
  const { isOpen = false, content = null, args = {} } = drawer;
  const ContentComponent = content ? contentMap[content] : null;

  if (!refreshToken) {
    return null;
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => dispatch(closeDrawer())}
      title={content ? content.replace(/_/g, ' ') : ''}
    >
      {ContentComponent && <ContentComponent {...args} />}
    </Drawer>
  );
};

export default GlobalDrawer;
