import { format } from 'date-fns';
import PropTypes from 'prop-types';

import ModalAction from 'components/ModalAction/ModalAction';
import { useCancelPaddleSubscription } from 'hooks/paddle/useCancelPaddleSubscription';

/**
 * Modal for confirming subscription cancellation
 * @param {Object} props Component props
 * @param {boolean} props.isOpen Whether modal is open
 * @param {Function} props.onClose Modal close handler
 * @param {Object} props.subscription Full subscription object
 * @param {Object} props.itemToRemove Subscription item to be cancelled
 * @param {Function} props.onSuccess Success callback
 */
export default function CancelPlanModal({
  isOpen,
  onClose,
  subscription,
  itemToRemove,
  onSuccess,
}) {
  const { removeSubscriptionItems, isLoading } = useCancelPaddleSubscription();

  const handleConfirmCancel = async () => {
    const keepItems = subscription.items
      .filter((item) => item.price.id !== itemToRemove.price.id)
      .map((item) => item.price.id);

    try {
      await removeSubscriptionItems(subscription.id, keepItems);
      onSuccess();
      onClose();
    } catch (err) {
      console.error('Failed to remove item:', err);
    }
  };

  if (!subscription || !itemToRemove) return null;

  return (
    <ModalAction
      isOpen={isOpen}
      onClose={onClose}
      title="Cancel Subscription"
      description={
        <>
          <p className="mb-2">This will cancel your subscription.</p>
          <p className="mb-2">
            You won&apos;t be billed in any future months and you&apos;ll have access until the end
            of this billing period ({format(new Date(itemToRemove.next_billed_at), 'MMM dd, yyyy')}
            ).
          </p>
          <p>
            The interface might take a second to update, but don&apos;t worry - it will be
            cancelled.
          </p>
        </>
      }
      actionType="delete"
      buttons={[
        {
          text: isLoading ? 'Cancelling...' : 'Yes, cancel subscription',
          onClick: handleConfirmCancel,
          variant: 'danger',
          disabled: isLoading,
        },
        {
          text: 'No, keep subscription',
          onClick: onClose,
          variant: 'secondary',
          disabled: isLoading,
        },
      ]}
    />
  );
}

CancelPlanModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subscription: PropTypes.object,
  itemToRemove: PropTypes.shape({
    price: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    next_billed_at: PropTypes.string.isRequired,
  }),
  onSuccess: PropTypes.func.isRequired,
};
