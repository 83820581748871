// In some page component

import { useParams } from 'react-router-dom';

import SourceGA4BigQueryFormActionButtons from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryFormActionButtons';
import SourceGA4BigQueryFormNavigation from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryFormNavigation';
import SourceGA4BigQueryFormStage1 from 'components/_forms/source_ga4_bigquery_exports/Stage1/SourceGA4BigQueryFormStage1';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const SourceGA4BigQueryFormPage = () => {
  const { id, pipelineId } = useParams(); // Get entityId from URL params
  const isUpdate = Boolean(id); // Determine if it's update mode
  const pageTitle = isUpdate ? 'Update GA4 Pipeline' : 'Create GA4 Pipeline'; // Set page title based on mode

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">{pageTitle}</h1>
          {isUpdate && (
            <SourceGA4BigQueryFormActionButtons pipelineId={pipelineId} sourceGA4BigQueryID={id} />
          )}
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="flex">
            <SourceGA4BigQueryFormNavigation isUpdate={isUpdate} activeTab="main" />
            <div className="flex-grow mt-5">
              <div className="text-gray-600 text-sm">
                <p>Let&apos;s get started! Pick the GA4 property you want to add.</p>
                <p>
                  You can add multiple properties and we&apos;ll roll them all up into one dataset.
                  You&apos;ll typically only want to do this if you&apos;re using multiple
                  properties for the same site.
                </p>
              </div>
              <SourceGA4BigQueryFormStage1 entityId={id} />
            </div>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default SourceGA4BigQueryFormPage;
