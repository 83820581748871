import { createSlice } from '@reduxjs/toolkit';

const sourceGA4BigQuerySlice = createSlice({
  name: 'sourceGA4BigQuery',
  initialState: {
    loading: true,
    propertyData: [],
    error: null,
  },
  reducers: {
    fetchGA4BigQueryFormData: (state) => {
      state.loading = true;
      state.error = null;
    },
    setGA4BigQueryFormData: (state, action) => {
      state.loading = false;
      state.propertyData = action.payload;
      state.error = null;
    },
    setGA4BigQueryFormError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchGA4BigQueryFormData, setGA4BigQueryFormData, setGA4BigQueryFormError } =
  sourceGA4BigQuerySlice.actions;

export default sourceGA4BigQuerySlice.reducer;
