import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import EmptyState from 'components/EmptyState/EmptyState';
import FlexListTable from 'components/FlexListTable/FlexListTable';
import FormSelect from 'components/Forms/FormSelect/FormSelect';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import TextBubble from 'components/TextBubble/TextBubble';
import { GA4_BIGQUERY_LINK_CREATE_FORM } from 'routes/constants';
import {
  useGetGA4AccountsQuery,
  useGetGA4BigQueryLinksQuery,
  useGetGA4PropertiesQuery,
} from 'services/rtkApi';
import { selectGCloudProjectId } from 'services/user/userSlice'; // Adjust the import path

const GA4BigQueryLinkTable = () => {
  const { control, watch } = useForm();
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const selectedAccount = watch('account');
  const selectedProperty = watch('property');
  const gcloudProjectId = useSelector(selectGCloudProjectId);

  const [isRefetching, setIsRefetching] = useState(false); // Track refetching state

  // Fetch GA4 accounts
  const {
    data: ga4Accounts,
    isLoading: accountsLoading,
    error: accountsError,
  } = useGetGA4AccountsQuery();

  // Fetch properties based on selected account
  const {
    data: properties,
    isLoading: propertiesLoading,
    error: propertiesError,
    refetch: refetchProperties,
  } = useGetGA4PropertiesQuery({ accountId: selectedAccount }, { skip: !selectedAccount });

  // Refetch properties when selectedAccount changes
  useEffect(() => {
    if (selectedAccount) {
      setIsRefetching(true);
      refetchProperties().finally(() => setIsRefetching(false));
    }
  }, [selectedAccount, refetchProperties]);

  // Combine loading states for properties
  const isPropertiesLoading = propertiesLoading || isRefetching;

  // Prepare query parameters for fetching BigQuery links
  const queryParams = useMemo(
    () => ({
      propertyId: selectedPropertyId,
      projectId: gcloudProjectId,
    }),
    [selectedPropertyId, gcloudProjectId]
  );

  // Fetch GA4 BigQuery links
  const { data, error, isLoading } = useGetGA4BigQueryLinksQuery(queryParams, {
    skip: !selectedPropertyId || !gcloudProjectId,
  });

  // Define table columns
  const columns = React.useMemo(
    () => [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Project', accessor: 'project' },
      { Header: 'Dataset Location', accessor: 'dataset_location' },
      {
        Header: 'Daily Export',
        accessor: 'daily_export_enabled',
        Cell: ({ value }) => (
          <TextBubble color={value ? 'green' : 'grey'} text={value ? 'Enabled' : 'Disabled'} />
        ),
      },
      {
        Header: 'Streaming Export',
        accessor: 'streaming_export_enabled',
        Cell: ({ value }) => (
          <TextBubble color={value ? 'green' : 'grey'} text={value ? 'Enabled' : 'Disabled'} />
        ),
      },
      {
        Header: 'Fresh Daily Export',
        accessor: 'fresh_daily_export_enabled',
        Cell: ({ value }) => (
          <TextBubble color={value ? 'green' : 'grey'} text={value ? 'Enabled' : 'Disabled'} />
        ),
      },
      { Header: 'Created', accessor: 'create_time' },
    ],
    []
  );

  // Map account data to options for the select input
  const accountOptions =
    ga4Accounts?.map((account) => ({
      value: account.account_id,
      label: account.display_name,
    })) || [];

  // Map property data to options for the select input
  const propertyOptions =
    properties?.map((prop) => ({
      value: prop.property_id,
      label: prop.display_name,
    })) || [];

  // Update selectedPropertyId when selectedProperty changes
  useEffect(() => {
    if (selectedProperty) {
      setSelectedPropertyId(selectedProperty);
    }
  }, [selectedProperty]);

  // Render loading spinner or error messages based on loading/error states
  if (accountsLoading) return <LoadingSpinner text="Loading GA4 accounts..." />;
  if (accountsError) return <div>Error loading GA4 accounts: {accountsError.message}</div>;
  if (propertiesError) return <div>Error loading properties: {propertiesError.message}</div>;

  return (
    <div className="mt-4">
      <form className="mb-4">
        {/* Account selection */}
        <FormSelect
          className="mb-4"
          fieldKey="account"
          label="Select GA4 Account"
          control={control}
          errors={{}}
          options={accountOptions}
          isLoading={accountsLoading}
          onChange={(option) => console.log('Account selected:', option)}
        />
        {/* Property selection */}
        <FormSelect
          fieldKey="property"
          label="Select Property"
          control={control}
          errors={{}}
          options={propertyOptions}
          isLoading={isPropertiesLoading}
          disabled={!selectedAccount}
          onChange={(option) => console.log('Property selected:', option)}
        />
      </form>

      {/* Display loading spinner or error message for BigQuery links */}
      {isLoading && <LoadingSpinner text="Loading..." className="mt-4" />}
      {error && <div>Error loading GA4 BigQuery links</div>}

      {/* Display empty state or table based on selectedPropertyId and data */}
      {!isLoading && !selectedPropertyId && (
        <EmptyState
          title="No property selected"
          description="Please select an account and property to view GA4 BigQuery links."
        />
      )}

      {!isLoading && selectedPropertyId && (!data || data.length === 0) && (
        <EmptyState
          title="No GA4 BigQuery links found"
          description="Get started by creating a new GA4 BigQuery link."
          buttonText="New GA4 BigQuery Link"
          buttonAction={GA4_BIGQUERY_LINK_CREATE_FORM}
        />
      )}

      {selectedPropertyId && data && data.length > 0 && (
        <FlexListTable columns={columns} data={data} hiddenColumnIds={['name']} />
      )}
    </div>
  );
};

GA4BigQueryLinkTable.propTypes = {};

export default GA4BigQueryLinkTable;
