import SignedOutContainer from 'components/SignedOutContainer/SignedOutContainer';

const LoginActivate = () => {
  return (
    <SignedOutContainer>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Check your email for an activation link
          </h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6 text-center">
          <div>Check your email for an activation email.</div>
        </div>
      </div>
    </SignedOutContainer>
  );
};

export default LoginActivate;
