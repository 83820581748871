import { useParams } from 'react-router-dom';

import CrudForm from 'components/Forms/CrudForm/CrudForm';
import {
  fieldInfoUpgradeSourceGA4BigQuery,
  upgradeSourceGA4BigQuerySchemaForm,
} from 'schemas/upgradeSourceGA4BigQuerySchema';
import { useGetDbtPackageQuery, useUpdateDbtPackageMutation } from 'services/rtkApi';

const UseCustomGetDbtPackageQuery = (pipelineId, packageName) => {
  // Use the existing RTK API hook with both pipelineId and packageName
  return useGetDbtPackageQuery({ pipelineId, packageName });
};

const UpgradeSourceGA4BigQueryForm = ({ onSuccess }) => {
  const { id, pipelineId } = useParams();
  const packageName = 'ga4'; // Replace with actual package name or logic to get it

  const queryHook = () => UseCustomGetDbtPackageQuery(pipelineId, packageName);
  const mutationHook = useUpdateDbtPackageMutation;

  return (
    <div className="mt-4">
      <CrudForm
        entityId={pipelineId}
        key={`upgrade-source-ga4-bigquery`}
        formType="update"
        mutationHook={mutationHook}
        queryHook={queryHook}
        schema={upgradeSourceGA4BigQuerySchemaForm}
        fieldInfo={fieldInfoUpgradeSourceGA4BigQuery}
        initialValues={{
          package_name: 'ga4',
        }}
        onSuccess={onSuccess}
        // Add any additional props needed for your form
      />
    </div>
  );
};

export default UpgradeSourceGA4BigQueryForm;
