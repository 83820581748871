import { z } from 'zod';

// Enum for Google Cloud regions
const GCloudRegion = z.enum([
  'us',
  'eu',
  'us-central1',
  'us-east1',
  'us-east4',
  'us-west1',
  'us-west2',
  'us-west3',
  'us-west4',
  'europe-west1',
  'europe-west2',
  'europe-west3',
  'europe-west4',
  'europe-west6',
  'asia-east1',
  'asia-east2',
  'asia-northeast1',
  'asia-northeast2',
  'asia-northeast3',
  'asia-south1',
  'asia-southeast1',
  'asia-southeast2',
  'australia-southeast1',
  'northamerica-northeast1',
  'southamerica-east1',
]);

export const bigqueryDatasetSchemaForm = z.object({
  id: z.number().optional(),
  name: z.string(),
  gcloud_project_id: z.string(),
  gcloud_region: GCloudRegion,
});
// Add a separate object for auto-set fields
export const autoSetFields = {
  gcloud_project_id: true,
};

export const fieldInfoBigQueryDataset = {
  id: { name: 'ID' },
  name: { name: 'Name', description: 'The name of the dataset' },
  gcloud_project_id: {
    name: 'Google Cloud Project ID',
    description: 'The ID of the Google Cloud project',
  },
  gcloud_region: {
    name: 'Google Cloud Region',
    description: 'The region of the Google Cloud project',
    useTitleCase: false,
  },
};
