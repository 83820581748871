import { unwrapResult } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from 'services/rtkApi';
import {
  fetchGA4BigQueryFormData,
  setGA4BigQueryFormData,
  setGA4BigQueryFormError,
} from 'services/sourceGA4BigQuery/sourceGA4BigQuerySlice';

function* fetchGA4BigQueryFormDataSaga(action) {
  try {
    const { pipelineId } = action.payload;

    // Use RTK Query hooks with unwrap
    const pipelineData = yield call(
      unwrapResult,
      api.endpoints.getPipelineById.initiate(pipelineId)
    );

    const datasetsData = yield call(
      unwrapResult,
      api.endpoints.getBigQueryDatasets.initiate(pipelineData.gcloud_project_id)
    );

    const propertyIds = datasetsData
      .filter((dataset) => /analytics_\d{7,12}/.test(dataset.name))
      .map((dataset) => dataset.name.match(/\d{7,12}/)[0]);

    const propertyQueries = yield all(
      propertyIds.map((id) =>
        call(
          unwrapResult,
          api.endpoints.getGA4PropertyById.initiate({
            propertyId: id,
            projectId: pipelineData.gcloud_project_id,
          })
        )
      )
    );

    const propertyData = propertyQueries
      .filter((result) => result)
      .map((result) => ({
        value: result.id,
        label: result.displayName,
      }));

    yield put(setGA4BigQueryFormData(propertyData));
  } catch (error) {
    console.error('Error fetching GA4 BigQuery form data:', error);
    yield put(setGA4BigQueryFormError(error.toString()));
  }
}

export function* sourceGA4BigQueryFormSaga() {
  yield takeLatest(fetchGA4BigQueryFormData.type, fetchGA4BigQueryFormDataSaga);
}
