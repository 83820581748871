import { z } from 'zod';

const parameterToUnnestSchema = z.array(
  z.object({
    event_parameter: z.string(),
    name: z.string(),
    value_type: z.enum(['string_value', 'int_value', 'float_value']).describe({
      string: 'String',
      int: 'Integer',
      float: 'Float',
    }),
    force_to_str: z.boolean(),
  })
);

/**
 * DEFINTIION: Columns to Unnest
 */
export const sourceGA4BigQueryColumnsToUnnestSchemaForm = z.object({
  event_parameters_to_unnest: parameterToUnnestSchema,
});

export const fieldInfoSourceGA4DBTColumnsToUnnest = {
  id: { name: 'ID' },
  name: { name: 'Name', description: 'The name of the source' },
  event_parameters_to_unnest: {
    name: 'Event Parameters to Unnest',
    description: 'Which event parameters do we want to unnest?',
    showLabel: false,
    showDescription: false,
    fields: {
      event_parameter: {
        name: 'Event Parameter',
      },
      name: {
        name: 'Name',
      },
    },
  },
};
