import { useParams } from 'react-router-dom';

import SourceGA4BigQueryFormAdvanced from 'components/_forms/source_ga4_bigquery_exports/Advanced/SourceGA4BigQueryFormAdvanced';
import SourceGA4BigQueryFormActionButtons from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryFormActionButtons';
import SourceGA4BigQueryFormNavigation from 'components/_forms/source_ga4_bigquery_exports/SourceGA4BigQueryFormNavigation';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const SourceGA4BigQueryAdvancedFormPage = () => {
  const { id, pipelineId } = useParams();
  const is_update = Boolean(id);
  const page_title = is_update ? 'Update GA4 Pipeline' : 'Create GA4 Pipeline';

  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">{page_title}</h1>
          {is_update && (
            <SourceGA4BigQueryFormActionButtons pipelineId={pipelineId} sourceGA4BigQueryID={id} />
          )}
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="flex">
            <SourceGA4BigQueryFormNavigation isUpdate={is_update} activeTab="advanced" />
            <div className="flex-grow mt-5">
              <div className="text-gray-600 text-sm">
                <p>Configure advanced settings for your GA4 BigQuery pipeline.</p>
                <p>
                  These settings allow you to fine-tune how your GA4 data is processed and stored.
                </p>
              </div>
              <SourceGA4BigQueryFormAdvanced entityId={id} />
            </div>
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default SourceGA4BigQueryAdvancedFormPage;
