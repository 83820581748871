import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button from 'components/Button/Button';
import Title from 'components/Title/Title';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

/**
 * PageAccordionStrip component for creating an accordion-style section with optional buttons.
 * @param {Object} props - Component props
 * @param {string} props.title - The title to display in the h1
 * @param {React.ReactNode} props.children - The content to be displayed in the accordion
 * @param {boolean} [props.hasTopBorder=false] - Whether to show a top border
 * @param {Array} [props.buttons] - Array of button configurations
 * @param {string} props.buttons[].text - Text for the button
 * @param {string} [props.buttons[].href] - Link for the button
 * @param {Function} [props.buttons[].onClick] - onClick function for the button
 * @param {Object} [props.buttons[].props] - Additional props for the Button component
 */
const PageAccordionStrip = ({
  title,
  children,
  hasTopBorder = false,
  className = '',
  buttons = [],
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => setIsOpen(!isOpen);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      toggleAccordion();
    }
  };

  return (
    <>
      <PageLayoutInnerStrip hasTopBorder={hasTopBorder} className={className}>
        <div className="flex items-center w-full">
          <div
            className={`flex items-center cursor-pointer `}
            onClick={toggleAccordion}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            aria-expanded={isOpen}
          >
            <div className="-ml-2">
              {isOpen ? (
                <ChevronDownIcon className="h-5 w-5 mr-2" aria-hidden="true" />
              ) : (
                <ChevronRightIcon className="h-5 w-5 mr-2" aria-hidden="true" />
              )}
            </div>
            <Title>{title}</Title>
          </div>
          {buttons.length > 0 && (
            <div className="ml-auto flex space-x-2">
              {buttons.map((button, index) => (
                <Button key={index} href={button.href} onClick={button.onClick} {...button.props}>
                  {button.text}
                </Button>
              ))}
            </div>
          )}
        </div>
      </PageLayoutInnerStrip>
      {isOpen && <PageLayoutInner className={'mb-6'}>{children}</PageLayoutInner>}
    </>
  );
};

PageAccordionStrip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  hasTopBorder: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string,
      onClick: PropTypes.func,
      props: PropTypes.object,
    })
  ),
};

PageAccordionStrip.defaultProps = {
  hasTopBorder: false,
  buttons: [],
};

export default PageAccordionStrip;
