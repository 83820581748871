import { PlusIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * EmptyState component for displaying when no content is available.
 * @param {Object} props - Component props
 * @param {string} props.title - Title text
 * @param {string} props.description - Description text
 * @param {string} [props.buttonText] - Text to display on the button
 * @param {string|Function} [props.buttonAction] - URL for Link or function for onClick
 */
const EmptyState = ({ title, description, buttonText, buttonAction }) => {
  const ButtonComponent = typeof buttonAction === 'string' ? Link : 'button';
  const buttonProps =
    typeof buttonAction === 'string' ? { to: buttonAction } : { onClick: buttonAction };

  return (
    <div className="text-center mt-4">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
      {buttonAction && (
        <div className="mt-6">
          <ButtonComponent
            {...buttonProps}
            className="inline-flex items-center rounded-md bg-red-damask-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            {buttonText}
          </ButtonComponent>
        </div>
      )}
    </div>
  );
};

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default EmptyState;
