// In some page component

import CommonActionSingle from 'components/CommonActionSingle/CommonActionSingle';
import SettingsSubMenu from 'components/SettingsSubMenu/SettingsSubMenu';
import { GOOGLE_API_PERMISSIONS_REGISTER, PLAN_MANAGEMENT } from 'routes/constants';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const AccountSettings = () => {
  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <SettingsSubMenu />
        </PageLayoutInnerStrip>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">Settings</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <div className="space-y-6 mt-6">
            <CommonActionSingle
              title="Permissions"
              description="In order for Pipeline to work we need permission to work with your GA4 and BigQuery. Here's where you share access."
              buttonName="Manage Permissions"
              href={GOOGLE_API_PERMISSIONS_REGISTER}
            />
            <CommonActionSingle
              title="Manage your plan"
              description="Need to change your plan, upgrade or cancel? Do it here."
              buttonName="Manage Plan"
              href={PLAN_MANAGEMENT}
            />
          </div>
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default AccountSettings;
