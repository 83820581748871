import { z } from 'zod';

/**
 * DEFINTIION: Attribution standalone table.
 */
export const sourceGA4BigQueryAttributionSchemaForm = z.object({
  // Do we want to run attribution models standalone?
  attribution_standalone: z.boolean().optional().default(false),

  // How many days lookback is our attribution window?
  model_attribution_window_days: z.number().optional().default(90),

  // Which metrics do we want to use for attribution?
  attribution_metrics: z.array(z.string()),
});
