import { useCallback, useEffect, useState } from 'react';

/**
 * Custom hook to manage button status with automatic reset
 * @param {number} resetDelay - Delay in milliseconds before resetting status to null
 * @returns {[string | null, Function]} - Current status and function to update status
 */
function useButtonStatus(resetDelay = 1000) {
  const [status, setStatus] = useState(null);

  const updateStatus = useCallback((newStatus) => {
    setStatus(newStatus);
  }, []);

  useEffect(() => {
    let timer;
    if (status === 'updated' || status === 'failed') {
      timer = setTimeout(() => {
        setStatus(null);
      }, resetDelay);
    }
    return () => clearTimeout(timer);
  }, [status, resetDelay]);

  return [status, updateStatus];
}

export default useButtonStatus;
