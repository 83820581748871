import { useMemo } from 'react';

import { useGetBigQueryTableSchemaQuery, useGetPipelineByIdQuery } from 'services/rtkApi';

/**
 * Custom hook to fetch pipeline data and BigQuery table schema.
 *
 * @param {string} pipelineId - The ID of the pipeline.
 * @return {Object} An object containing pipeline data, table schema, and loading states.
 */
const useGA4TableSchemaBaseTable = (pipelineId) => {
  const { data: pipelineData, isLoading: isLoadingPipeline } = useGetPipelineByIdQuery(pipelineId, {
    skip: !pipelineId,
  });

  const { data: tableSchema, isLoading: isLoadingSchema } = useGetBigQueryTableSchemaQuery(
    {
      project_id: pipelineData?.gcloud_project_id,
      dataset_id: pipelineData?.bq_output_dataset,
      table_id: 'ga4_base_events',
    },
    {
      skip: !pipelineData,
    }
  );

  const isLoading = useMemo(
    () => isLoadingPipeline || isLoadingSchema,
    [isLoadingPipeline, isLoadingSchema]
  );

  return { pipelineData, tableSchema, isLoading };
};

export default useGA4TableSchemaBaseTable;
