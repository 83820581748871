import GA4BigQueryLinkTable from 'components/_tables/ga4_bigquery_links/GA4BigQueryLinkTable';
import PageLayout from 'templates/PageLayout';
import PageLayoutInner from 'templates/PageLayoutInner';
import PageLayoutInnerStrip from 'templates/PageLayoutInnerStrip';

const GA4BigQueryLinkListPage = () => {
  return (
    <PageLayout isItGrey>
      <div>
        <PageLayoutInnerStrip>
          <h1 className="text-xl font-semibold leading-7 text-gray-900">GA4 BigQuery Links</h1>
        </PageLayoutInnerStrip>
        <PageLayoutInner>
          <GA4BigQueryLinkTable />
        </PageLayoutInner>
      </div>
    </PageLayout>
  );
};

export default GA4BigQueryLinkListPage;
