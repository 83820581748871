import PropTypes from 'prop-types';

function PageLayoutInner({ children, className }) {
  return <div className={`px-4 sm:px-6 lg:px-8 ${className}`}>{children}</div>;
}

export default PageLayoutInner;

PageLayoutInner.propTypes = {
  children: PropTypes.node,
};
