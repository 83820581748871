import { useState } from 'react';

/**
 * Hook for managing Paddle subscriptions
 * @returns {Object} Methods and state for subscription management
 */
export function useCancelPaddleSubscription() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Cancel an entire subscription
   * @param {string} subscription_id - Paddle subscription ID
   */
  const cancelSubscription = async (subscription_id) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://api.paddle.com/subscriptions/${subscription_id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: 'canceled',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }

      return await response.json();
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Remove specific items from a subscription
   * @param {string} subscription_id - Paddle subscription ID
   * @param {Array} keepItems - Array of items to keep (price_ids)
   */
  const removeSubscriptionItems = async (subscription_id, keepItems) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://api.paddle.com/subscriptions/${subscription_id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: keepItems.map((priceId) => ({
            price_id: priceId,
            quantity: 1,
          })),
          proration_billing_mode: 'prorated_immediately',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update subscription items');
      }

      return await response.json();
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    cancelSubscription,
    removeSubscriptionItems,
    isLoading,
    error,
  };
}
