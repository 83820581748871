// Auth
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const REGISTER = '/register';
export const FORGOT_PASSWORD = '/forgot-password';
export const SOCIAL_LOGIN_REDIRECT = '/social/callback';
export const SOCIAL_REGISTER = '/social-register';
export const LOGIN_ACTIVATE = '/login/activate';
export const LOGIN_ACTIVATED = '/login/activated';

// Google API OAuth2

export const GOOGLE_API_PERMISSIONS_CALLBACK = '/permissions/google-api/callback';
export const GOOGLE_API_PERMISSIONS_REGISTER = '/permissions/google-api/register';

// Accounts
export const ACCOUNT_CREATE_FORM = '/accounts/new';
export const ACCOUNT_UPDATE_FORM = '/accounts/edit/:id';
export const ACCOUNT_LIST = '/accounts';

// Pipeline
export const PIPELINE_CREATE_FORM = '/pipelines/new';
export const PIPELINE_UPDATE_FORM = '/pipelines/edit/:id';
export const PIPELINE_LIST = '/pipelines';
export const PIPELINE_ALL_SOURCE_LIST = '/pipelines/:id/';

// Pipeline Runs
export const PIPELINE_RUN_VIEW = '/pipeline-runs/:id';

// GA4 BigQuery Export
export const SOURCE_GA4_BIGQUERY_CREATE_FORM = '/pipelines/:pipelineId/source/ga4-bigquery/new';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_RAW =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/raw';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_COLUMNS_TO_UNNEST =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/columns-to-unnest';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_ECOMMERCE =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/ecommerce';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_CONVERSIONS =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/conversions';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_CONVERSIONS_NEW =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/conversions/new';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_ADVANCED_CONFIG =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/advanced-config';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_EVENT_PROPERTIES =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/event-properties';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_SESSION_PROPERTIES =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/session-properties';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_USER_PROPERTIES =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/user-properties';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_ADVANCED_CONFIG_BQ =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/advanced-config-bq';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_ATTRIBUTION =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/custom-attribution';
export const SOURCE_GA4_BIGQUERY_UPDATE_FORM_CHANNEL_GROUPINGS =
  '/pipelines/:pipelineId/source/ga4-bigquery/edit/:id/channel-groupings';
export const SOURCE_GA4_BIGQUERY_UPGRADE_FORM =
  '/pipelines/:pipelineId/source/ga4-bigquery/upgrade/:id';

export const SOURCE_GA4_BIGQUERY_LIST = '/pipelines/:pipelineId/source/ga4-bigquery';

// GA4 BigQuery Export
export const GA4_BIGQUERY_LINK_CREATE_FORM = '/ga4-bigquery-links/new';
export const GA4_BIGQUERY_LINK_UPDATE_FORM = '/ga4-bigquery-links/edit/:id';
export const GA4_BIGQUERY_LINK_LIST = '/ga4-bigquery-links';

// Users
export const USER_CREATE_FORM = '/users/new';
export const USER_UPDATE_FORM = '/users/edit/:id';
export const USER_LIST = '/users';

// User months

// Legal
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const PRIVACY_POLICY = '/privacy-policy';

// Pricing
export const PLAN_MANAGEMENT = '/plan-management';

// Settings
export const SETTINGS = '/settings';
export const ACCOUNT_SETTINGS = '/account/settings';

// Homepage
export const HOME = '/';

// API URLS
export const GOOGLE_API_OAUTH2_CALLBACK = '/google-api-oauth2/callback';
