import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errors: [],
  formErrors: {},
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    addGlobalError: (state, action) => {
      state.errors.push(action.payload);
    },
    removeGlobalError: (state) => {
      state.errors.shift(); // Remove the first error after it's displayed
    },
    setFormError: (state, action) => {
      const { formName, errors } = action.payload;

      if (!state.formErrors[formName]) {
        state.formErrors[formName] = [];
      }

      if (typeof errors === 'string') {
        state.formErrors[formName].push(errors);
      } else if (Array.isArray(errors)) {
        state.formErrors[formName] = [...state.formErrors[formName], ...errors];
      } else if (typeof errors === 'object' && errors !== null) {
        // Convert object errors into formatted messages
        const messages = Object.entries(errors).map(([key, value]) => `${key}: ${value}`);
        state.formErrors[formName] = [...state.formErrors[formName], ...messages];
      }
    },
    clearFormError: (state, action) => {
      const formName = action.payload;
      if (formName) {
        state.formErrors[formName] = [];
      } else {
        state.formErrors = {};
      }
    },
  },
});

export const { addGlobalError, removeGlobalError, setFormError, clearFormError } =
  errorSlice.actions;
export default errorSlice.reducer;
