import { useEffect, useMemo, useState } from 'react';

import { getSchemaFromZod } from 'components/Forms/formUtils';

/**
 * Custom hook for handling tab logic in forms
 * @param {Object} schema - The form schema
 * @param {Array} tabs - The tab configuration (optional)
 * @param {Object} errors - Form errors
 * @returns {Object} Tab handling methods and state
 */
const useTabHandling = (schema, tabs, errors) => {
  const initializeTabs = (tabs, schema) => {
    const schemaShape = getSchemaFromZod(schema).shape;

    if (!tabs || tabs.length === 0) {
      return [
        {
          key: 'default',
          tabName: 'Form',
          fields: Object.keys(schemaShape),
        },
      ];
    }
    return tabs.map((tab) => ({
      ...tab,
      fields:
        tab.fieldList ||
        Object.keys(schemaShape).filter((field) => schemaShape[field]['x-tab'] === tab.key),
    }));
  };

  const [tabConfig, setTabConfig] = useState(initializeTabs(tabs, schema));
  const [tabErrorCounts, setTabErrorCounts] = useState({});
  const tabKeys = useMemo(() => tabConfig.map((tab) => tab.key), [tabConfig]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const currentTab = tabConfig[currentTabIndex];

  useEffect(() => {
    const calculateErrorCounts = () => {
      const counts = {};
      tabConfig.forEach((tab) => {
        counts[tab.key] = tab.fields.reduce((count, field) => {
          return errors[field] ? count + 1 : count;
        }, 0);
      });
      return counts;
    };

    const errorCounts = calculateErrorCounts();
    setTabErrorCounts(errorCounts);
  }, [errors, tabConfig]);

  const nextTab = () => {
    if (currentTabIndex < tabKeys.length - 1) {
      setCurrentTabIndex(currentTabIndex + 1);
    }
  };

  const previousTab = () => {
    if (currentTabIndex > 0) {
      setCurrentTabIndex(currentTabIndex - 1);
    }
  };

  const setCurrentTabByKey = (key) => {
    const index = tabKeys.indexOf(key);
    if (index !== -1) {
      setCurrentTabIndex(index);
    }
  };

  return {
    tabConfig,
    tabErrorCounts,
    currentTab,
    currentTabIndex,
    tabKeys,
    nextTab,
    previousTab,
    setCurrentTabByKey,
    hasTabs: tabs && tabs.length > 0,
  };
};

export default useTabHandling;
